import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/core';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles(() => ({
   root: {
      position: 'relative'
   }
}));

function Chart({
   dateType,
   displayPeriod,
   responsePackageChartData,
   responsePackageFreeChartData,
   resultdate,
   labels,
   previousYear,
   className,
   ...rest
}) {
   const classes = useStyles();
   const theme = useTheme();
   const location = useLocation();
   const navigate = useNavigate();
   const data = {
      datasets: [
         {
            label: 'จำนวนตอบแบบสอบถามเสียเงิน',
            backgroundColor: theme.palette.primary.main,
            data: responsePackageChartData,
            barThickness: responsePackageChartData.length < 30 ? 20 : responsePackageChartData.length > 62 ? 4 : 12,
            maxBarThickness: responsePackageChartData.length < 30 ? 20 : responsePackageChartData.length > 62 ? 4 : 12,
            barPercentage: 0.9,
            categoryPercentage: 1
         },
         {
            label: 'จำนวนตอบแบบสอบถามฟรี',
            backgroundColor: theme.palette.secondary.main,
            data: responsePackageFreeChartData,
            barThickness: responsePackageFreeChartData.length < 30 ? 20 : responsePackageFreeChartData.length > 62 ? 4 : 12,
            maxBarThickness: responsePackageFreeChartData.length < 30 ? 20 : responsePackageFreeChartData.length > 62 ? 4 : 12,
            barPercentage: 0.9,
            categoryPercentage: 1
         },
      ],
      labels
   };

   const options = {
      plugins: {
         datalabels: {
            formatter: function (value, context) {
               return value;
            },
            align: 'top',
            anchor: 'end',
            color: theme.palette.text.secondary,
            display: (context) => {
               const index = context.dataIndex;
               const value = context.dataset.data[index];
               return !Boolean(responsePackageFreeChartData.length > 100 && value === 0);
            }
         }
      },
      responsive: true,
      maintainAspectRatio: false,
      // animation: false,
      cornerRadius: 4,
      legend: {
         display: false
      },
      layout: {
         padding: 0
      },
      scales: {
         xAxes: [
            {
               stacked: false,
               gridLines: {
                  display: false,
                  drawBorder: false
               },
               ticks: {
                  // display: false
                  padding: 20,
                  callback: (value) => {
                     const [day, month, year] = value.split('/').map(Number);
                     const date = new Date(year, month - 1, day);
                     const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                     const dayOfWeek = daysOfWeek[date.getDay()];
                     return dayOfWeek === 'Sunday' ? '🔴 ' + value : value;
                  },
                  fontColor: theme.palette.text.secondary
               }
            }
         ],
         yAxes: [
            {
               // stacked: true,
               gridLines: {
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: theme.palette.divider,
                  drawBorder: false,
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                  zeroLineColor: theme.palette.divider
               },
               ticks: {
                  padding: 20,
                  fontColor: theme.palette.text.secondary,
                  beginAtZero: true,
                  min: 0,
                  maxTicksLimit: 20,
                  callback: (value) => (value > 0 ? `${value}` : value)
               },
               afterDataLimits: (scale) => {
                  if (scale.max < 10) {
                     scale.max += 1;
                  } else if (scale.max < 2000) {
                     scale.max += 100;
                  } else if (scale.max > 2000) {
                     scale.max += 200;
                  }
               }
            }
         ]
      },
      tooltips: {
         enabled: true,
         mode: 'index',
         intersect: false,
         caretSize: 10,
         yPadding: 20,
         xPadding: 20,
         borderWidth: 1,
         borderColor: theme.palette.divider,
         backgroundColor: theme.palette.background.default,
         titleFontColor: theme.palette.text.primary,
         bodyFontColor: theme.palette.text.secondary,
         footerFontColor: theme.palette.text.secondary,
         callbacks: {
            legend: () => { },
            title: ([tooltipItem]) => {
               return `${tooltipItem.xLabel}`;
            },
            label: (tooltipItem, data) => {
               const index = tooltipItem.index;
               let label = `${data.datasets[tooltipItem.datasetIndex].label} ${tooltipItem.yLabel} คน `;
               return label;
            }
         }
      }
   };

   const handleOnClickElement = ([chartElement]) => {
      if (!chartElement) return;
      if (location.pathname !== '/app/survey-response-summary-report') return;
      const index = chartElement._index;
      const dateType = {
         in_day: 'day',
         in_month: 'month',
         in_year: 'year'
      };
      const dateFormat = {
         in_day: 'DD/MM/YYYY',
         in_month: 'MMM/YYYY',
         in_year: 'YYYY'
      };

      const date = moment(labels[index], dateFormat[displayPeriod]).toISOString();
      const searchParams = {
         start_date: date,
         date_type: dateType[displayPeriod],
      };
      const url = `/app/survey-response-report?${createSearchParams(searchParams)}`;
      window.open(url, '_blank');
   };

   return (
      <div className={clsx(classes.root, className)} {...rest}>
         <Bar data={data} options={options} onElementsClick={handleOnClickElement} />
      </div>
   );
}

Chart.propTypes = {
   className: PropTypes.string,
   labels: PropTypes.array.isRequired
};

export default Chart;
