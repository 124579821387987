import React, { useState } from 'react';
import moment from 'moment';

import { IconButton, Tooltip, makeStyles } from '@material-ui/core/';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import FilesDropzone from 'src/components/FilesDropzone'
import { Tag as TagIcon, Users as UsersIcon, FileText as FileTextIcon, Edit3 as Edit } from 'react-feather';
import { calculateDiscountedPrice, calculateUnusePrice, parseNumber, parsePriceForm } from 'src/utils/orderUtils';
import { useDispatch, useSelector } from 'react-redux';
import { usePermissions } from 'src/hooks/usePermissions';
import DialogDate from './DialogDate';
import { getOrderById, displayLoading, cleanOrder } from 'src/redux/actions/customerOrderAction';

const useStyles = makeStyles((theme) => ({
   invoiceIcon: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[3]
   },
   btnCancel: {
      backgroundColor: '#fb4c6ee0',
      color: '#fff'
   },
   companyName: {
      fontSize: 13
   },
   packageLabel: {
      marginRight: 16
   },
   packageName: {},
   featureText: {
      marginLeft: 8
   },
   seatsIcon: {
      marginRight: 8,
      color: theme.palette.text.secondary
   },
   discountIcon: {
      marginRight: 8,
      color: theme.palette.text.secondary
   },
   pdfBtn: {
      backgroundColor: '#f8f8f8',
      width: 300,
      fontWeight: 600,
      textTransform: 'capitalize'
   },
   companyInfo: {
      [theme.breakpoints.down(461)]: {
         flexDirection: 'column',
         gap: 8
      }
   },
   packageInfo: {
      marginTop: 8,
      marginBottom: 8,
      padding: '8px 32px',
      '& .package_dt': {
         flexWrap: 'wrap',
         gap: 20,
         [theme.breakpoints.down('sm')]: {
            gap: 4
         }
      },
      [theme.breakpoints.down('sm')]: {
         padding: '8px 16px'
      },
      [theme.breakpoints.down('xs')]: {
         padding: '8px 0'
      }
   },
   addonsBox: {
      flexDirection: 'column',
      gap: 0,
      [theme.breakpoints.down('sm')]: {
         gap: 8
      },
      [theme.breakpoints.down('xs')]: {
         gap: 16
      }
   }
}));

const companyData = {
   logo: '/static/images/EDD-Logo.png',
   name: 'EXCELLENT DIGITAL DEVELOPMENT COMPANY LIMITED (Headquarters)',
   address1: '128/202-203, Payathai Plaza Bldg. 19th Floor, Suite A,B.',
   address2: 'Payathai Road, Thungpayathai Sub-district, Rajthavee District',
   address3: 'Bangkok, THAILAND 10400',
   email: 'contact@enablesurvey.com',
   phone: '+66-2090-9099',
   taxId: '0105561054090'
};

const CompanyInfo = () => {
   const classes = useStyles();
   return (
      <Box
         p={{ xs: 0, md: 2 }}
         display="flex"
         flexGrow={1}
         flexDirection={{ xs: 'column', sm: 'row' }}
         justifyContent="space-between"
         style={{ gap: 8 }}
      >
         <Box>
            <img src={companyData.logo} alt="company logo" width={100} height="auto" />
         </Box>
         <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="body1" color="textPrimary" className={classes.companyName}>
               <b>{companyData.name}</b>
            </Typography>
            <Typography variant="caption" color="textSecondary">
               {companyData.email}
            </Typography>
            <Typography variant="caption" color="textSecondary">
               {companyData.phone}
            </Typography>
            <Typography variant="caption" color="textSecondary">
               TAX ID No.{' ' + companyData.taxId}
            </Typography>
         </Box>
         <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            maxWidth={{ xs: '100%', sm: '50%', md: '30%' }}
         >
            <Typography variant="caption" color="textSecondary">
               {companyData.address1} + {companyData.address2} + {companyData.address3}
            </Typography>
         </Box>
      </Box>
   );
};

const OrderReceipt = ({ order, pdfHost }) => {
   const classes = useStyles();
   const { can, cannot } = usePermissions();
   const unuseDays = useSelector((state) => state.customerOrder.unuseDays);
   const { receipt, invoice } = order;
   const dispatch = useDispatch();
   const [openDialog, setOpenDialog] = useState(false);

   const handleClickGetReceipt = (locale) => {
      window.open(`${pdfHost}/api/${locale}/order/receipt/${order.id}`, '_blank');
   };

   const handleOpenDialog = () => {
      setOpenDialog(true);
   };

   const handleCloseDialog = async (type) => {
      if (type === 'save') {
         setOpenDialog(false);
         dispatch(displayLoading(true));
         const shouldLoading = false;
         await dispatch(getOrderById(order.id, shouldLoading));
         await dispatch(displayLoading(false));
      } else {
         setOpenDialog(false);
      }
   };

   const calSubtotal = () => {
      if (!order.package) return 0;
      const packagePrice = calculateDiscountedPrice(order.package?.total_price, order.package.discount);
      const addonsPrice =
         order.addons?.reduce((prev, cur) => {
            const allTotalPrice = calculateDiscountedPrice(parseNumber(cur.total_price), cur.discount);
            return allTotalPrice + prev;
         }, 0) || 0;
      const subtotal = packagePrice + addonsPrice;
      return parsePriceForm(subtotal);
   };

   const calUnusePrice = () => {
      if (!order.package) return 0;
      const packagePrice = calculateDiscountedPrice(order.package?.total_price, order.package?.discount);
      return calculateUnusePrice(packagePrice, order.discount, unuseDays, order.package?.package_duration_days);
   };

   if (!order.package) {
      return (
         <div>
            <Box display="flex" height="190px" alignItems="center" justifyContent="center">
               <Typography width="auto" component={Box} textAlign="center">
                  <b>Package not found ..!!</b>
                  <p>Make sure package in quotation tab is selected or this order is not canceled</p>
               </Typography>
            </Box>
         </div>
      );
   }
   // if (order.status !== 'verified') {
   if (!order.receipt) {
      return (
         <div>
            <Box display="flex" height="190px" alignItems="center" justifyContent="center">
               <Typography width="auto" component={Box} textAlign="center">
                  <b>This order has not verified yet ..!</b>
                  <p>Make sure uploaded slip has been approved</p>
               </Typography>
            </Box>
         </div>
      );
   }
   if (cannot(['VIEW', 'OPERATE'], 'ORDER_RECEIPT')) {
      return (
         <Box display="flex" alignItems="center" justifyContent="center" height="120px">
            <Typography>You are not permission.</Typography>
         </Box>
      );
   }

   return (
      <Box border="1px solid #ddd" borderRadius={8} p={2}>
         <CompanyInfo />
         <Box px={{ xs: 0, sm: 2, md: 4 }} py={1}>
            <Box
               mx="auto"
               // minHeight={150}
               p={3}
               elevation={0}
               bgcolor="#f4f6f8"
               display="flex"
               flexDirection="column"
            >
               <Box display="flex" alignItems="center">
                  <Box width={200}>
                     <Typography variant="caption" color="textPrimary">
                        <b> Receipt No {' : '}</b>
                     </Typography>
                  </Box>
                  <Box ml={3}>
                     <Typography variant="caption" color="textSecondary">
                        {receipt?.receipt_no}
                     </Typography>
                  </Box>
               </Box>
               <Box display="flex" alignItems="center">
                  <Box width={200}>
                     <Typography variant="caption" color="textPrimary">
                        <b> Receipt Date {' : '}</b>
                     </Typography>
                  </Box>
                  <Box ml={3}>
                     <Typography variant="caption" color="textSecondary">
                        {receipt ? moment(receipt.receipt_date).format('DD/MM/YYYY') : 'Unknown'}
                     </Typography>
                     {can(['EDIT'], 'ORDER_RECEIPT') ? (
                        <Tooltip title="Edit Receipt Date" arrow>
                           <IconButton marginLeft="15px" size="small" onClick={handleOpenDialog}>
                              <Edit width={'70%'} />
                           </IconButton>
                        </Tooltip>
                     ) : null}
                  </Box>
               </Box>
               <Box display="flex" alignItems="center">
                  <Box width={200}>
                     <Typography variant="caption" color="textPrimary">
                        <b>Invoice No {' : '}</b>
                     </Typography>
                  </Box>
                  <Box ml={3}>
                     <Typography variant="caption" color="textSecondary">
                        {invoice?.invoice_no}
                     </Typography>
                  </Box>
               </Box>
               {order?.invoice_no_2c2p && (
                  <Box display="flex" alignItems="center">
                     <Box width={200}>
                        <Typography variant="caption" color="textPrimary">
                           <b>Invoice No (2c2p) {' : '}</b>
                        </Typography>
                     </Box>
                     <Box ml={3}>
                        <Typography variant="caption" color="textSecondary">
                           {order?.invoice_no_2c2p}
                        </Typography>
                     </Box>
                  </Box>
               )}
               <Box display="flex" alignItems="baseline">
                  <Box width={200}>
                     <Typography variant="caption" color="textPrimary">
                        <b>Package duration {' : '}</b>
                     </Typography>
                  </Box>
                  <Box ml={3}>
                     <Typography variant="caption" color="textSecondary">
                        {order.package
                           ? `${moment(order.package?.package_begin_at).format('DD/MM/YYYY HH:mm')}-${moment(
                                order.package?.package_expired_at
                             ).format('DD/MM/YYYY HH:mm')}`
                           : 'Unknown'}
                     </Typography>
                  </Box>
               </Box>
               {/* <Typography variant="subtitle1" color="textSecondary">
               Receipt No : {receipt?.receipt_no}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
               Receipt Date : {receipt ? moment(receipt.receipt_date).format('DD/MM/YYYY') : 'Unknnown'}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
               Invoice No : {invoice?.invoice_no}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
               Package duration :{' '}
               {order.package
                  ? moment(order.package?.package_begin_at).format('DD/MM/YYYY HH:mm') +
                    '-' +
                    moment(order.package?.package_expired_at).format('DD/MM/YYYY HH:mm')
                  : 'Unknown'}
            </Typography> */}
            </Box>
         </Box>
         <Box className={classes.packageInfo}>
            <Box className="package_dt" display="flex" alignItems="center">
               <Box display="flex" alignItems="center" flexWrap="wrap">
                  <Typography className={classes.packageLabel} variant="body1" color="textPrimary">
                     <b>Package</b>
                  </Typography>
                  <Typography className={classes.packageName} variant="body1" color="textSecondary">
                     {`${order.package?.name} (${order?.package?.detail?.name})`}
                  </Typography>
               </Box>
               <Box display="flex" alignItems="center" border="1px solid #ddd" px={1} borderRadius={4}>
                  <UsersIcon size={20} className={classes.seatsIcon} />
                  <Typography className={classes.packageName} variant="body1" color="textSecondary">
                     Seats {order.package.seats}
                  </Typography>
               </Box>
               {order.package.discount?.length > 0 && (
                  <Box display="flex" alignItems="center" border="1px solid #ddd" px={1} py={0.25} borderRadius={4}>
                     <TagIcon size={20} className={classes.discountIcon} />
                     <Typography variant="caption" color="textSecondary">
                        Discount{' '}
                        {order.package.discount
                           .map((dc) => Number(dc.discount) + (dc.discount_cal_type === 'direct' ? '฿' : '%'))
                           .join(' , ')}
                     </Typography>
                  </Box>
               )}
               <Box pr={2} flexGrow={1} style={{ textAlign: 'right' }}>
                  <Typography variant="body1" color="textPrimary">
                     <b>
                        {parsePriceForm(calculateDiscountedPrice(order.package?.total_price, order.package.discount)) ||
                           0}
                     </b>
                  </Typography>
               </Box>
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Package Type {' : '}
                  </Typography>
               </Box>
               <Box ml={1}>
                  <Typography variant="caption" color="textSecondary">
                     {(order.package?.package_type || 'Unknown').toUpperCase()}{' '}
                  </Typography>
               </Box>
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Subscription type {' : '}
                  </Typography>
               </Box>
               <Box ml={1}>
                  <Typography variant="caption" color="textSecondary">
                     {(order.order_type || 'Unknown').toUpperCase()}
                     {order.remain_receipt_no ? ` (from. ${order.remain_receipt_no})` : ''}
                  </Typography>
               </Box>
            </Box>
            <Box py={1} display={(order.addons?.length ?? 0) === 0 ? 'block' : 'none'}>
               <Divider />
            </Box>
            <Box
               display={(order.addons?.length ?? 0) > 0 ? 'flex' : 'none'}
               className={classes.addonsBox}
               my={1}
               border="1px solid #ddd"
               borderRadius={4}
               p={1}
            >
               <Box display="flex" borderBottom="1px solid #ddd" flexWrap="wrap">
                  <Box
                     width={{ xs: '100%', md: '45%' }}
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                     style={{ fontWeight: 'bold' }}
                  >
                     Items
                  </Box>
                  <Box
                     width={{ xs: '33%', md: '10%' }}
                     textAlign={{ xs: 'left', md: 'center' }}
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                  >
                     Price
                  </Box>
                  <Box
                     width={{ xs: '33%', md: '10%' }}
                     textAlign="center"
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                  >
                     Quantity
                  </Box>
                  <Box
                     width={{ xs: '33%', md: '10%' }}
                     textAlign={{ xs: 'right', md: 'center' }}
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                  >
                     Use Days
                  </Box>
                  <Box
                     width={{ xs: '50%', md: '15%' }}
                     textAlign={{ xs: 'left', md: 'center' }}
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                  >
                     Discounts
                  </Box>
                  <Box
                     width={{ xs: '50%', md: '10%' }}
                     textAlign={{ xs: 'right', md: 'center' }}
                     component={Typography}
                     variant="caption"
                     color="textSecondary"
                  >
                     Total Price
                  </Box>
               </Box>
               {(order.addons || []).map((addon, index) => (
                  <Box display="flex" alignItems="center" key={addon.feature_code + '--' + index} flexWrap="wrap">
                     <Box width={{ xs: '100%', md: '45%' }}>
                        <Typography variant="caption" color="textSecondary" style={{ fontWeight: 'bold' }}>
                           Addon {index + 1}
                        </Typography>
                        <Typography variant="caption" color="textPrimary" className={classes.featureText}>
                           {addon.detail?.feature_label || addon.feature_code}
                        </Typography>
                     </Box>
                     <Box
                        display="flex"
                        alignItems="center"
                        width={{ xs: '33%', md: '10%' }}
                        justifyContent={{ xs: 'flex-start', md: 'center' }}
                     >
                        <Typography
                           variant="caption"
                           color="textSecondary"
                           //  className={classes.featureText}
                        >
                           {parsePriceForm(addon.price) + '฿'}
                        </Typography>
                     </Box>
                     <Box display="flex" alignItems="center" width={{ xs: '33%', md: '10%' }} justifyContent="center">
                        <Typography
                           variant="caption"
                           color="textSecondary"
                           //  className={classes.featureText}
                        >
                           {addon.quantity + ' ' + (addon.unit ?? 'Days')}
                        </Typography>
                     </Box>
                     <Box
                        display="flex"
                        alignItems="center"
                        width={{ xs: '33%', md: '10%' }}
                        justifyContent={{ xs: 'flex-end', md: 'center' }}
                     >
                        <Typography variant="caption" color="textSecondary">
                           {addon.feature_type === 'amount' ? '-' : (addon.days ? addon.days : 0) + ' Days'}
                        </Typography>
                     </Box>
                     <Box
                        display="flex"
                        alignItems="center"
                        width={{ xs: '50%', md: '15%' }}
                        justifyContent={{ xs: 'flex-start', md: 'center' }}
                     >
                        {addon.discount.length > 0 ? (
                           <Box display="flex" alignItems="center">
                              <Typography
                                 variant="caption"
                                 color="textSecondary"
                                 //  className={classes.featureText}
                              >
                                 {addon.discount
                                    .map((dc) => Number(dc.discount) + (dc.discount_cal_type === 'direct' ? '฿' : '%'))
                                    .join(' , ')}
                              </Typography>
                           </Box>
                        ) : (
                           <Typography variant="caption" color="textSecondary">
                              {' - '}
                           </Typography>
                        )}
                     </Box>

                     <Box
                        display="flex"
                        alignItems="center"
                        width={{ xs: '50%', md: '10%' }}
                        justifyContent={{ xs: 'flex-end', md: 'center' }}
                     >
                        <Typography variant="caption" color="textPrimary">
                           <b>
                              {parsePriceForm(calculateDiscountedPrice(parseNumber(addon.total_price), addon.discount))}
                           </b>
                        </Typography>
                     </Box>
                  </Box>
               ))}
            </Box>
            <Box display="flex" pr={{ xs: 1, md: 4 }}>
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Sub Total
                  </Typography>
               </Box>
               <Box ml={2} width={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>{calSubtotal()}</b>
                  </Typography>
               </Box>
            </Box>
            {order.discount.length > 0 && (
               <Box display="flex" pr={{ xs: 1, md: 4 }}>
                  <Box flexGrow={1} />
                  <Box>
                     <Typography variant="caption" color="textPrimary">
                        Discount
                     </Typography>
                  </Box>
                  <Box ml={2} minWidth={70} textAlign="right">
                     <Typography variant="caption" color="textPrimary">
                        <b>
                           {order.discount
                              .map((dc) => Number(dc.discount) + (dc.discount_cal_type === 'direct' ? '฿' : '%'))
                              .join(' , ')}
                        </b>
                     </Typography>
                  </Box>
               </Box>
            )}
            {parseNumber(order.order_package_remain_price) > 0 && (
               <Box display="flex" pr={{ xs: 1, md: 4 }}>
                  <Box flexGrow={1} />
                  <Box>
                     <Typography variant="caption" color="textPrimary">
                        Remain
                     </Typography>
                  </Box>
                  <Box ml={2} width={70} textAlign="right">
                     <Typography variant="caption" color="textPrimary">
                        <b>{parsePriceForm(order.order_package_remain_price) || ''}</b>
                     </Typography>
                  </Box>
               </Box>
            )}
            {calUnusePrice() > 0 && (
               <Box display="flex" pr={{ xs: 1, md: 4 }}>
                  <Box flexGrow={1} />
                  <Box>
                     <Typography variant="caption" color="textPrimary">
                        Unuse Price
                     </Typography>
                  </Box>
                  <Box ml={2} width={70} textAlign="right">
                     <Typography variant="caption" color="textPrimary">
                        <b>{parsePriceForm(calUnusePrice()) || ''}</b>
                     </Typography>
                  </Box>
               </Box>
            )}
            <Box display="flex" pr={{ xs: 1, md: 4 }}>
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Grand Total
                  </Typography>
               </Box>
               <Box ml={2} width={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>{parsePriceForm(order.total_price_include_vat) || ''}</b>
                  </Typography>
               </Box>
            </Box>
            <Box display="flex" pr={{ xs: 1, md: 4 }}>
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Withholding tax
                  </Typography>
               </Box>
               <Box ml={2} width={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>{parseNumber(order.additional_tax_rate) || ''}%</b>
                  </Typography>
               </Box>
            </Box>
            <Box display="flex" pr={{ xs: 1, md: 4 }}>
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Net price
                  </Typography>
               </Box>
               <Box ml={2} width={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>{parsePriceForm(order?.summary_price) || '0'}</b>
                  </Typography>
               </Box>
            </Box>
         </Box>
         <Box display="flex" justifyContent="center" my={2}>
            <Button
               className={classes.pdfBtn}
               onClick={() => handleClickGetReceipt('th')}
               variant="text"
               color="primary"
               fullWidth
               startIcon={<FileTextIcon size={16} />}
            >
               Receipt TH
            </Button>
            <Box mx={1} />
            <Button
               className={classes.pdfBtn}
               onClick={() => handleClickGetReceipt('en')}
               variant="text"
               color="primary"
               fullWidth
               startIcon={<FileTextIcon size={16} />}
            >
               Receipt EN
            </Button>
         </Box>
         {/* </Box> */}
         <DialogDate open={openDialog} close={handleCloseDialog} data={order} type={'receipt'} />
      </Box>
   );
};

export default OrderReceipt;
