import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { startQuotation } from 'src/redux/actions/quotationAction';
import { usePermissions } from 'src/hooks/usePermissions';
import Radio from '@material-ui/core/Radio';
import { useDialog } from 'src/hooks/dialog';
import axiosToken from 'src/common/AxiosToken';
import { displayLoading, getOrderById } from 'src/redux/actions/customerOrderAction';
import { useSnackbar } from 'notistack';
import { Edit as EditIcon, Trash as DeleteIcon, Copy as CopyIcon, Eye as EyeIcon } from 'react-feather';
import { calculateDiscountedPrice, calculateUnusePrice, parseNumber, parsePriceForm } from 'src/utils/orderUtils';

const useStyles = makeStyles((theme) => ({
   root: {
      border: ({ isSelected }) => `1px solid ${isSelected ? theme.palette.primary.main : '#ddd'}`,
      borderRadius: 4,
      padding: '16px 24px',
      marginBottom: 8,
      position: 'relative',
      marginLeft: 42
      // cursor: 'pointer'
   },
   packageLabel: {
      marginRight: 16
   },
   radioControl: {
      position: 'absolute',
      left: '-43px',
      top: 0,
      backgroundColor: '#f4f6f8',
      borderRadius: '8px 0 0 8px'
   },
   packageName: {},
   actionBox: {
      marginTop: 16,
      display: 'flex',
      alignItems: 'center',
      '& > *': {
         marginLeft: 8
      },
      [theme.breakpoints.down('430')]: {
         flexDirection: 'column',
         alignItems: 'stretch'
      }
   },
   addonsBox: {
      flexDirection: 'column',
      border: '1px solid #ddd',
      borderRadius: 8,
      gap: 0,
      [theme.breakpoints.down('sm')]: {
         gap: 8
      },
      [theme.breakpoints.down('xs')]: {
         gap: 12
      }
   },
   addonListLabel: {
      marginRight: 8,
      fontWeight: 'bold'
   },
   radioDisabled: {
      '&.Mui-checked': {
         color: `${theme.palette.primary.main} !important`
      }
   },
   actionBtn: {
      borderRadius: 4,
      [theme.breakpoints.down('430')]: {
         marginTop: 8
      }
   }
}));

const QuotationSet = ({
   quotationSet,
   isSelectAble,
   editAble,
   setOpenQuotationCreator,
   copyAble,
   deleteAble,
   chosenQuotationSetId
}) => {
   const classes = useStyles({ isSelected: chosenQuotationSetId === quotationSet.id });
   const dispatch = useDispatch();
   const dialog = useDialog();
   const { can } = usePermissions();
   const { enqueueSnackbar } = useSnackbar();
   const orderId = useSelector((state) => state.customerOrder.order?.id);
   const packageRemainPrice = useSelector((state) => state.customerOrder.order?.quotation?.package_remain_price);
   const unuseDays = useSelector((state) => state.customerOrder.unuseDays);
   const editQuotation = (e) => {
      e.stopPropagation();
      dispatch(startQuotation(quotationSet, can));
      setOpenQuotationCreator([true]);
   };

   const copyQuotation = (e) => {
      e.stopPropagation();
      const { id, ...quotationToCreate } = quotationSet; // remove quotation.id for create as new
      dispatch(startQuotation(quotationToCreate, can));
      setOpenQuotationCreator([true]);
   };

   const viewQuotation = () => {
      dispatch(startQuotation(quotationSet, can));
      setOpenQuotationCreator([true, true]);
   };

   const handleSelectQuotation = async () => {
      if (chosenQuotationSetId === quotationSet.id) return;
      const isConfirm = await dialog.confirm({
         title: 'ยืนยันการทำรายการ ?',
         message: 'กดปุ่มตกลง เพื่อยืนยันการเลือก Quotation นี้'
      });
      if (!isConfirm) return;

      try {
         dispatch(displayLoading(true));
         const urlSelectQuotation = `order2/${orderId}/select-set`;
         const res = await axiosToken({
            method: !!chosenQuotationSetId ? 'PUT' : 'POST',
            url: urlSelectQuotation,
            data: { selected_set_id: quotationSet.id }
         });
         enqueueSnackbar(res.data.message, {
            variant: 'success',
            autoHideDuration: 2000
         });
         dispatch(getOrderById(orderId, true));
      } catch (error) {
         dialog.error(error);
         dispatch(displayLoading(false));
      }
   };

   const deleteQuotationSet = async () => {
      const isConfirm = await dialog.confirm({
         title: 'ยืนยันการทำรายการ ?',
         message: 'กดปุ่มตกลง เพื่อยืนยันการลบ Quotation นี้'
      });
      if (!isConfirm) return;
      try {
         dispatch(displayLoading(true));
         const urlDelQuotationSet = `order2/${orderId}/set/${quotationSet.id}`;
         const res = await axiosToken.delete(urlDelQuotationSet);
         enqueueSnackbar(res.data.message, {
            variant: 'success',
            autoHideDuration: 2000
         });
         dispatch(getOrderById(orderId, true));
      } catch (error) {
         dialog.error(error);
         dispatch(displayLoading(false));
      }
   };

   const calSubtotal = () => {
      const packagePrice = calculateDiscountedPrice(quotationSet.package?.total_price, quotationSet.package.discount);
      const addonsPrice =
         quotationSet.addons?.reduce((prev, cur) => parseNumber(cur.item.summary_price) + prev, 0) || 0;
      const subtotal = packagePrice + addonsPrice;
      return subtotal;
   };

   const calUnusePrice = () => {
      const packagePrice = calculateDiscountedPrice(quotationSet.package?.total_price, quotationSet.package.discount);
      return calculateUnusePrice(
         packagePrice,
         quotationSet.discount,
         unuseDays,
         quotationSet.package?.detail?.package_duration_days
      );
   };
   return (
      <div className={classes.root}>
         <Box className={classes.radioControl} onClick={(e) => e.stopPropagation()}>
            <Radio
               color="primary"
               classes={{ disabled: classes.radioDisabled }}
               disabled={!isSelectAble}
               checked={chosenQuotationSetId === quotationSet.id}
               onChange={handleSelectQuotation}
               value={quotationSet.id}
               name={quotationSet.id}
               inputProps={{ 'aria-label': quotationSet.id }}
            />
         </Box>
         <Box
            display="flex"
            alignItems="baseline"
            borderBottom="1px solid #ddd"
            pb={0.5}
            style={{ flexWrap: 'wrap', gap: '8px' }}
         >
            <Box display="flex" alignItems="center" style={{ flexWrap: 'wrap' }}>
               <Typography className={classes.packageLabel} variant="body1" color="textPrimary">
                  <b>Package</b>
               </Typography>
               <Typography className={classes.packageName} variant="body1" color="textSecondary">
                  {quotationSet.package?.package_name + ' (' + quotationSet?.package?.detail?.name + ')'}
               </Typography>
            </Box>
            <Box display="flex" alignItems="center" style={{ flexWrap: 'wrap' }}>
               <Typography className={classes.packageName} variant="body1" color="textSecondary">
                  Seats {quotationSet.package.seats}
               </Typography>
            </Box>
            {quotationSet.package.discount.length > 0 && (
               <Box display="flex" alignItems="center" style={{ flexWrap: 'wrap' }}>
                  <Typography
                     variant="caption"
                     color="textSecondary"
                     //  className={classes.featureText}
                  >
                     Discount{' '}
                     {quotationSet.package.discount
                        .map((dc) => Number(dc.discount) + (dc.discount_cal_type === 'direct' ? '฿' : '%'))
                        .join(' , ')}
                  </Typography>
               </Box>
            )}
            <Box flexGrow={1} style={{ textAlign: 'right' }}>
               <Typography variant="body1" color="textPrimary">
                  <b>
                     {parsePriceForm(
                        calculateDiscountedPrice(quotationSet.package?.total_price, quotationSet.package.discount)
                     ) || 0}
                  </b>
               </Typography>
            </Box>
         </Box>
         <Box
            my={1}
            p={1}
            mr={-1}
            display={!!quotationSet.addons?.length ? 'flex' : 'none'}
            className={classes.addonsBox}
         >
            {(quotationSet.addons || []).map((addon, index) => (
               <Box
                  display="flex"
                  alignItems="center"
                  key={addon.feature_code + '--' + index}
                  style={{ flexWrap: 'wrap' }}
               >
                  <Typography variant="caption" color="textSecondary" className={classes.addonListLabel}>
                     Addon {index + 1}
                  </Typography>
                  <Typography
                     variant="caption"
                     color="textPrimary"
                     //  className={classes.featureText}
                  >
                     {addon.detail?.feature_label || addon.feature_code}
                  </Typography>
                  <Box mx={{ xs: 0.5, sm: 2 }} />
                  <Box display="flex" alignItems="center">
                     <Typography
                        variant="caption"
                        color="textSecondary"
                        //  className={classes.featureText}
                     >
                        {!addon.feature_type && parsePriceForm(addon.price) + '฿ x' + addon.days + ' Days'}
                        {addon.feature_type === 'amount' &&
                           parsePriceForm(addon.price) + '฿ x' + addon.quantity + ' ' + (addon.unit ?? '')}
                        {['space', 'team-role'].includes(addon.feature_type) &&
                           parsePriceForm(addon.price) +
                              '฿ x' +
                              addon.quantity +
                              ' ' +
                              (addon.unit ?? '') +
                              ' x' +
                              addon.days +
                              ' Days'}
                     </Typography>
                  </Box>
                  <Box mx={{ xs: 0.5, sm: 2 }} />
                  {addon.discount.length > 0 && (
                     <Box display="flex" alignItems="center">
                        <Typography
                           variant="caption"
                           color="textSecondary"
                           //  className={classes.featureText}
                        >
                           Discount{' '}
                           {addon.discount
                              .map((dc) => Number(dc.discount) + (dc.discount_cal_type === 'direct' ? '฿' : '%'))
                              .join(' , ')}
                        </Typography>
                     </Box>
                  )}

                  <Box flexGrow={1} style={{ textAlign: 'right' }}>
                     <Typography variant="caption" color="textPrimary">
                        <b>
                           {parseNumber(addon.item.summary_price)?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                           }) || ''}
                        </b>
                     </Typography>
                  </Box>
               </Box>
            ))}
         </Box>
         <Box display="flex" alignItems="center">
            <Box flexGrow={1} />
            <Box>
               <Typography variant="caption" color="textPrimary">
                  Sub Total
               </Typography>
            </Box>
            <Box ml={2} width={70} textAlign="right">
               <Typography variant="caption" color="textPrimary">
                  <b>{parsePriceForm(calSubtotal())}</b>
               </Typography>
            </Box>
         </Box>
         {quotationSet.discount.length > 0 && (
            <Box display="flex" alignItems="center">
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Discount
                  </Typography>
               </Box>
               <Box ml={2} minWidth={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>
                        {quotationSet.discount
                           .map((dc) => Number(dc.discount) + (dc.discount_cal_type === 'direct' ? '฿' : '%'))
                           .join(' , ')}
                     </b>
                  </Typography>
               </Box>
            </Box>
         )}
         {parseNumber(packageRemainPrice) > 0 && (
            <Box display="flex" alignItems="center">
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Remain
                  </Typography>
               </Box>
               <Box ml={2} width={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>-{parsePriceForm(packageRemainPrice) || ''}</b>
                  </Typography>
               </Box>
            </Box>
         )}
         {calUnusePrice() > 0 && (
            <Box display="flex" alignItems="center">
               <Box flexGrow={1} />
               <Box>
                  <Typography variant="caption" color="textPrimary">
                     Unuse Price
                  </Typography>
               </Box>
               <Box ml={2} width={70} textAlign="right">
                  <Typography variant="caption" color="textPrimary">
                     <b>-{parsePriceForm(calUnusePrice()) || ''}</b>
                  </Typography>
               </Box>
            </Box>
         )}
         <Box display="flex" alignItems="center">
            <Box flexGrow={1} />
            <Box>
               <Typography variant="caption" color="textPrimary">
                  Grand Total
               </Typography>
            </Box>
            <Box ml={2} width={70} textAlign="right">
               <Typography variant="caption" color="textPrimary">
                  <b>{parsePriceForm(quotationSet.total_price_include_vat) || ''}</b>
               </Typography>
            </Box>
         </Box>
         <Box display="flex" alignItems="flex-end">
            <Box flexGrow={1} />
            <Box>
               <Typography variant="caption" color="textPrimary">
                  Withholding tax
               </Typography>
            </Box>
            <Box ml={2} width={70} textAlign="right">
               <Typography variant="caption" color="textPrimary">
                  <b>{parseNumber(quotationSet.additional_tax_rate) || ''}%</b>
               </Typography>
            </Box>
         </Box>
         <Box display="flex" alignItems="center">
            <Box flexGrow={1} />
            <Box>
               <Typography variant="caption" color="textPrimary">
                  Net price
               </Typography>
            </Box>
            <Box ml={2} width={70} textAlign="right">
               <Typography variant="caption" color="textPrimary">
                  <b>{parsePriceForm(quotationSet.summary_price) || '0'}</b>
               </Typography>
            </Box>
         </Box>
         <Box className={classes.actionBox}>
            <Box flexGrow={1} />
            <Button
               className={classes.actionBtn}
               startIcon={<EyeIcon size={16} />}
               variant="outlined"
               onClick={viewQuotation}
               size="small"
               color="primary"
            >
               View
            </Button>
            <Button
               className={classes.actionBtn}
               startIcon={<EditIcon size={16} />}
               variant="outlined"
               disabled={!editAble}
               onClick={editQuotation}
               size="small"
               color="primary"
            >
               Edit
            </Button>
            <Button
               className={classes.actionBtn}
               startIcon={<DeleteIcon size={16} />}
               variant="outlined"
               disabled={!deleteAble}
               onClick={deleteQuotationSet}
               size="small"
               color="primary"
            >
               delete
            </Button>
            <Button
               className={classes.actionBtn}
               startIcon={<CopyIcon size={16} />}
               disabled={!copyAble}
               onClick={copyQuotation}
               size="small"
               variant="outlined"
               color="primary"
            >
               copy
            </Button>
         </Box>
      </div>
   );
};

export default QuotationSet;
