import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, alpha, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import CheckIcon from '@material-ui/icons/Check';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {
   addAddonDiscount,
   addPackageAddon,
   addPackageDiscount,
   addQuotationSetDiscount,
   changeAddedAddons,
   changeAddonDiscount,
   changePackageDiscount,
   changeQuotatinSetDiscount,
   cleanUp,
   onQuotationChangeWithKey,
   onCheckedPackageNormalFree,
   removeAddon,
   removeAddonDiscount,
   removePackageDiscount,
   removeQuotatinSetDiscount,
   saveQuotationSet,
   selectPackage,
   startQuotation
} from 'src/redux/actions/quotationAction';
import { v4 as uuid } from 'uuid';
import { Tag as TagIcon, CheckCircle as CheckCircleIcon } from 'react-feather';
import { useDialog } from 'src/hooks/dialog';
// import { usePermissions } from 'src/hooks/usePermissions';
import { useSnackbar } from 'notistack';
import LoadingBackdrop from 'src/components/loading/LoadingBackdrop';
import { parseNumber, parsePriceForm } from 'src/utils/orderUtils';
import _ from 'json-bigint';

const useStyles = makeStyles((theme) => ({
   dialogPaper: {
      backgroundColor: '#fafbfd'
   },
   packageContainer: {
      border: '1px solid #eaeef1',
      padding: 8,
      borderRadius: 8,
      backgroundColor: '#fff'
   },
   packagePriceText: {
      fontWeight: 700,
      fontSize: 19
   },
   formControlSelectPackage: {},
   customPackageNameLabel: {
      marginRight: 8,
      width: 187,
      whiteSpace: 'nowrap'
   },
   customPackageNameTextField: {
      width: 400
   },
   listFeaturePackage: {
      padding: 8,
      height: 180,
      overflowY: 'auto',
      overflowX: 'hidden'
   },
   featureText: {
      display: 'block',
      fontSize: 14
   },
   featureQuantityBox: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 32,
      border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
      borderRadius: 4,
      paddingLeft: 4,
      paddingRight: 4,
      backgroundColor: alpha(theme.palette.primary.main, 0.08)
   },
   featureQuantityLabel: {
      color: theme.palette.text.hint
   },
   featureQuantityValue: {
      marginLeft: 16,
      color: theme.palette.primary.main
   },
   packagePriceAreaBox: {
      flexDirection: 'column'
   },
   packagePriceBox: {
      flex: 1,
      padding: 8,
      margin: 4,
      border: '1px solid #ddd',
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
         justifyContent: 'unset'
      }
   },
   priceBoxLabel: {
      position: 'absolute',
      top: 8,
      left: 8,
      [theme.breakpoints.down('xs')]: {
         position: 'unset'
      }
   },
   addonsContainer: {
      marginTop: 16,
      padding: 8,
      border: '1px solid #eaeef1',
      borderRadius: 8,
      backgroundColor: '#fff'
   },
   addonsHeadder: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      paddingRight: 8,
      paddingLeft: 8,
      [theme.breakpoints.down('sm')]: {
         display: 'none'
      }
   },
   defaultAddonPrice: {
      whiteSpace: 'nowrap',
      marginLeft: 24,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 4,
      paddingRight: 4,
      border: '1px solid #ddd',
      borderRadius: 8,
      '& .priceTag': {
         marginRight: 4
      }
   },
   addonBox: {
      flexWrap: 'unset',
      gap: 'unset',
      margin: 'unset',
      [theme.breakpoints.down('sm')]: {
         flexWrap: 'wrap',
         gap: 8,
         margin: '8px 0px'
      }
   },
   smallTextField: {
      paddingTop: 6,
      paddingBottom: 6
   },
   iconButtonRmAddon: {
      padding: 6
   },
   discountChip: {
      marginRight: 4
   },
   conclusionContainer: {
      marginTop: 16,
      padding: 8,
      border: '1px solid #eaeef1',
      borderRadius: 8,
      minHeight: 250,
      backgroundColor: '#fff'
   },
   conclusionLeft: {
      // borderRight: '1px solid #ddd'
   },
   conclusionRight: {
      // paddingLeft: 8
   },
   withholdingTaxTLabel: {
      whiteSpace: 'nowrap'
   },
   withholdingTaxTextField: {
      marginTop: 5,
      '& input': {
         textAlign: 'right',
         color: `${theme.palette.primary.main} !important`,
         fontWeight: 600
      }
   },
   selectAddonLabel: {
      marginRight: 16
   },
   selectAddonTextField: {
      flexGrow: 1
   },
   disabledTextField: {
      backgroundColor: '#fff !important',
      color: `#a0a0a0 !important`,
      '&.MuiSelect-icon': {
         display: 'none'
      }
   },
   helperText: {
      position: 'absolute',
      bottom: '-20px',
      whiteSpace: 'nowrap',
      margin: '0px 4px',
      fontSize: '10px'
   },
   listSubheader: {
      backgroundColor: 'rgb(221 221 221 )',
      lineHeight: 2
   },
   listSub: {
      top: '28px',
      lineHeight: '2',
      backgroundColor: '#f7f7f7'
   }
}));

const MuiChip = ({ disabled, onDelete, onClick, ...props }) => {
   return <Chip {...props} onDelete={disabled ? undefined : onDelete} onClick={disabled ? undefined : onClick} />;
};

const QuotationCreator = ({ orderType, previousPackageOrderType, type, isOpen, close, isViewMode }) => {
   const classes = useStyles();
   const dialog = useDialog();
   const dispatch = useDispatch();
   // const { can } = usePermissions();
   const { enqueueSnackbar } = useSnackbar();
   const quotation = useSelector((state) => state.quotation);

   const [discountForm, setDiscountForm] = useState({
      open: false,
      anchorEl: null,
      target: '',
      discount: '',
      disabled: false,
      discount_cal_type: 'percentage',
      errorValue: null
   });

   const [autoCalculate, setAutoCalculate] = useState({
      open: false,
      anchorEl: null,
      target: '',
      price: '',
      disabled: false,
      price_cal_type: 'direct',
      errorValue: null
   });

   const [newAddon, setNewAddon] = useState({ openToSelect: false, code: null });

   const handleCloseQuotationCreator = () => {
      dispatch(cleanUp());
      close();
   };
   const handleCloseAutoCalculate = () => {
      setAutoCalculate({
         open: false,
         anchorEl: null,
         target: '',
         price: '',
         disabled: false,
         price_cal_type: 'direct',
         errorValue: null
      });
   };

   const handleCloseDiscount = () => {
      setDiscountForm({
         open: false,
         anchorEl: null,
         target: '',
         discount: '',
         disabled: false,
         discount_cal_type: 'percentage',
         errorValue: null
      });
   };

   const handleDiscountChange = (e) => {
      const { name, value } = e.target;
      setDiscountForm((prev) => ({
         ...prev,
         [name]: value,
         errorValue: null
      }));
   };

   const handlePriceChange = (e) => {
      const { name, value } = e.target;
      setAutoCalculate((prev) => ({
         ...prev,
         [name]: value,
         errorValue: null
      }));
   };

   const isValidDiscount = (number) => {
      const decimalPart = (number % 1).toFixed(2);
      return parseFloat(decimalPart) === number % 1;
   };

   const saveDiscountForm = () => {
      const { open, anchorEl, target, errorValue, ...restDiscount } = discountForm;
      const [targetFor, id] = target.split('||');
      const value = Number(discountForm.discount);
      if (!value || isNaN(value) || value <= 0 || !/^\d+(\.\d{1,2})?$/.test(value)) {
         setDiscountForm((prev) => ({
            ...prev,
            errorValue: 'Please insert correct value'
         }));
         return;
      }
      if (discountForm.id) {
         const targetFun = {
            quotationSetDiscount: (data) => dispatch(changeQuotatinSetDiscount(data)),
            packageDiscount: (data) => dispatch(changePackageDiscount(data)),
            addonDiscount: (data) => dispatch(changeAddonDiscount(data, id))
         };
         const dispatchFun = targetFun[targetFor];
         dispatchFun(restDiscount);
      } else {
         const targetFun = {
            quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
            packageDiscount: (data) => dispatch(addPackageDiscount(data)),
            addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
         };
         const dispatchFun = targetFun[targetFor];
         const newId = uuid();
         dispatchFun({ ...restDiscount, id: newId });
      }
      handleCloseDiscount();
   };

   const saveAutoCalculate = () => {
      const { open, anchorEl, target, errorValue, ...restDiscount } = autoCalculate;
      const [targetFor, id] = target.split('||');
      const value = Number(autoCalculate.price);
      if (!value || isNaN(value) || value <= 0) {
         setAutoCalculate((prev) => ({
            ...prev,
            errorValue: 'Please insert correct value'
         }));
         return;
      } else if (value >= quotation.quotationSetNetTotal) {
         setAutoCalculate((prev) => ({
            ...prev,
            errorValue: 'Please insert value that less than Net price'
         }));
         return;
      } else if (targetFor === 'quotationSetExcludedVat' && value >= quotation.quotationSetExcludedVat) {
         setAutoCalculate((prev) => ({
            ...prev,
            errorValue: 'Please insert value that less than Excluded Vat'
         }));
         return;
      }
      if (targetFor === 'quotationSetDiscount') {
         if (autoCalculate.price_cal_type === 'direct') {
            const GrandTotal = quotation.quotationSetGrandTotal;
            const totalPrice = ((100 + 7) * value) / (100 + 7 - quotation.quotationSetWithholdingTax);
            const discountDirect = (GrandTotal - totalPrice).toFixed(2);
            restDiscount.discount = discountDirect;
            restDiscount.discount_cal_type = 'direct';
            const targetFun = {
               quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
               packageDiscount: (data) => dispatch(addPackageDiscount(data)),
               addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
            };

            const dispatchFun = targetFun[targetFor];
            const newId = uuid();
            dispatchFun({ ...restDiscount, id: newId });
         } else {
            const GrandTotal =
               quotation.quotationSetGrandTotal +
               Number(quotation.quotationSetRemain) +
               quotation.quotationSetUnusePrice;
            //ราคาที่เอาไปหาส่วนลด = (100+tax)*input/(100+tax)-with
            const totalPrice =
               ((100 + 7) * value) / (100 + 7 - quotation.quotationSetWithholdingTax) +
               Number(quotation.quotationSetRemain) +
               quotation.quotationSetUnusePrice;
            //หาเปอร์เซนส่วนลด
            const discountPercent = Math.floor(100 - (totalPrice * 100) / GrandTotal);
            //ราคาที่ลดเปอร์เซ็นส่วนลดแล้ว
            const priceWithDiscount = GrandTotal - (GrandTotal * discountPercent) / 100;
            //ส่วนหักที่เหลือนอกจากเปอร์เซน
            const discountDirect = Number((Math.round(priceWithDiscount) - totalPrice).toFixed(2));

            const dataSet = [
               { discount: discountPercent, type: 'percentage' },
               { discount: discountDirect, type: 'direct' }
            ];
            if (priceWithDiscount > totalPrice && discountDirect !== 0 && discountPercent !== 0) {
               for (let i = 0; i < dataSet.length; i++) {
                  restDiscount.discount = dataSet[i].discount;
                  restDiscount.discount_cal_type = dataSet[i].type;
                  const targetFun = {
                     quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
                     packageDiscount: (data) => dispatch(addPackageDiscount(data)),
                     addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
                  };
                  const dispatchFun = targetFun[targetFor];
                  const newId = uuid();
                  dispatchFun({ ...restDiscount, id: newId });
               }
            } else if (discountPercent !== 0 && discountDirect === 0) {
               restDiscount.discount = discountPercent;
               restDiscount.discount_cal_type = 'percentage';
               const targetFun = {
                  quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
                  packageDiscount: (data) => dispatch(addPackageDiscount(data)),
                  addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
               };

               const dispatchFun = targetFun[targetFor];
               const newId = uuid();
               dispatchFun({ ...restDiscount, id: newId });
            } else if (discountDirect !== 0 && discountPercent === 0) {
               restDiscount.discount = discountDirect;
               restDiscount.discount_cal_type = 'direct';
               const targetFun = {
                  quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
                  packageDiscount: (data) => dispatch(addPackageDiscount(data)),
                  addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
               };

               const dispatchFun = targetFun[targetFor];
               const newId = uuid();
               dispatchFun({ ...restDiscount, id: newId });
            }
         }
      } else if (targetFor === 'quotationSetGrandTotal') {
         if (autoCalculate.price_cal_type === 'direct') {
            const grandtotal = quotation.quotationSetGrandTotal;
            const totalPrice = ((100 + 7) * value) / (100 + 7 - quotation.quotationSetWithholdingTax);
            const discountDirect = (grandtotal - totalPrice).toFixed(2);
            restDiscount.discount = discountDirect;
            restDiscount.discount_cal_type = 'direct';
            const targetFun = {
               quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
               packageDiscount: (data) => dispatch(addPackageDiscount(data)),
               addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
            };

            const dispatchFun = targetFun['quotationSetDiscount'];
            const newId = uuid();
            dispatchFun({ ...restDiscount, id: newId });
         } else {
            const grandTotal =
               quotation.quotationSetGrandTotal +
               Number(quotation.quotationSetRemain) +
               quotation.quotationSetUnusePrice;
            //ราคาที่เอาไปหาส่วนลด = (100+tax)*input/(100+tax)-with
            const totalPrice =
               ((100 + 7) * value) / (100 + 7 - quotation.quotationSetWithholdingTax) +
               Number(quotation.quotationSetRemain) +
               quotation.quotationSetUnusePrice;
            //หาเปอร์เซนส่วนลด
            const discountPercent = Math.floor(100 - (totalPrice * 100) / grandTotal);
            //ราคาที่ลดเปอร์เซ็นส่วนลดแล้ว
            const priceWithDiscount = grandTotal - (grandTotal * discountPercent) / 100;
            //ส่วนหักที่เหลือนอกจากเปอร์เซน
            const discountDirect = Number((Math.round(priceWithDiscount) - totalPrice).toFixed(2));

            const dataSet = [
               { discount: discountPercent, type: 'percentage' },
               { discount: discountDirect, type: 'direct' }
            ];
            if (priceWithDiscount > totalPrice && discountDirect !== 0 && discountPercent !== 0) {
               for (let i = 0; i < dataSet.length; i++) {
                  restDiscount.discount = dataSet[i].discount;
                  restDiscount.discount_cal_type = dataSet[i].type;
                  const targetFun = {
                     quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
                     packageDiscount: (data) => dispatch(addPackageDiscount(data)),
                     addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
                  };
                  const dispatchFun = targetFun['quotationSetDiscount'];
                  const newId = uuid();
                  dispatchFun({ ...restDiscount, id: newId });
               }
            } else if (discountPercent !== 0 && discountDirect === 0) {
               restDiscount.discount = discountPercent;
               restDiscount.discount_cal_type = 'percentage';
               const targetFun = {
                  quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
                  packageDiscount: (data) => dispatch(addPackageDiscount(data)),
                  addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
               };
               const dispatchFun = targetFun['quotationSetDiscount'];
               const newId = uuid();
               dispatchFun({ ...restDiscount, id: newId });
            } else if (discountDirect !== 0 && discountPercent === 0) {
               restDiscount.discount = discountDirect;
               restDiscount.discount_cal_type = 'direct';
               const targetFun = {
                  quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
                  packageDiscount: (data) => dispatch(addPackageDiscount(data)),
                  addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
               };

               const dispatchFun = targetFun['quotationSetDiscount'];
               const newId = uuid();
               dispatchFun({ ...restDiscount, id: newId });
            }
         }
      } else if (targetFor === 'quotationSetExcludedVat') {
         if (autoCalculate.price_cal_type === 'direct') {
            const GrandTotal = quotation.quotationSetGrandTotal;
            const totalPrice = ((100 + 7) * value) / (100 + 7 - quotation.quotationSetWithholdingTax);
            const vat = (value * 7) / 100;
            const discountDirect = (GrandTotal - (totalPrice + vat)).toFixed(2);
            restDiscount.discount = discountDirect;
            restDiscount.discount_cal_type = 'direct';
            const targetFun = {
               quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
               packageDiscount: (data) => dispatch(addPackageDiscount(data)),
               addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
            };
            const dispatchFun = targetFun['quotationSetDiscount'];
            const newId = uuid();
            dispatchFun({ ...restDiscount, id: newId });
         } else {
            const GrandTotal =
               quotation.quotationSetGrandTotal +
               Number(quotation.quotationSetRemain) +
               quotation.quotationSetUnusePrice;
            const vat = (value * 7) / 100;
            //ราคาที่เอาไปหาส่วนลด = (100+tax)*input/(100+tax)-with
            const totalPrice =
               ((100 + 7) * value) / (100 + 7 - quotation.quotationSetWithholdingTax) +
               Number(quotation.quotationSetRemain) +
               quotation.quotationSetUnusePrice +
               vat;
            //หาเปอร์เซนส่วนลด
            const discountPercent = Math.floor(100 - (totalPrice * 100) / GrandTotal);
            //ราคาที่ลดเปอร์เซ็นส่วนลดแล้ว
            const priceWithDiscount = GrandTotal - (GrandTotal * discountPercent) / 100;
            //ส่วนหักที่เหลือนอกจากเปอร์เซน
            const discountDirect = Number((Math.round(priceWithDiscount) - totalPrice).toFixed(2));

            const dataSet = [
               { discount: discountPercent, type: 'percentage' },
               { discount: discountDirect, type: 'direct' }
            ];
            if (priceWithDiscount > totalPrice && discountDirect !== 0 && discountPercent !== 0) {
               for (let i = 0; i < dataSet.length; i++) {
                  restDiscount.discount = dataSet[i].discount;
                  restDiscount.discount_cal_type = dataSet[i].type;
                  const targetFun = {
                     quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
                     packageDiscount: (data) => dispatch(addPackageDiscount(data)),
                     addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
                  };
                  const dispatchFun = targetFun['quotationSetDiscount'];
                  const newId = uuid();
                  dispatchFun({ ...restDiscount, id: newId });
               }
            } else if (discountPercent !== 0 && discountDirect === 0) {
               restDiscount.discount = discountPercent;
               restDiscount.discount_cal_type = 'percentage';
               const targetFun = {
                  quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
                  packageDiscount: (data) => dispatch(addPackageDiscount(data)),
                  addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
               };

               const dispatchFun = targetFun['quotationSetDiscount'];
               const newId = uuid();
               dispatchFun({ ...restDiscount, id: newId });
            } else if (discountDirect !== 0 && discountPercent === 0) {
               restDiscount.discount = discountDirect;
               restDiscount.discount_cal_type = 'direct';
               const targetFun = {
                  quotationSetDiscount: (data) => dispatch(addQuotationSetDiscount(data)),
                  packageDiscount: (data) => dispatch(addPackageDiscount(data)),
                  addonDiscount: (data) => dispatch(addAddonDiscount(data, id))
               };
               const dispatchFun = targetFun['quotationSetDiscount'];
               const newId = uuid();
               dispatchFun({ ...restDiscount, id: newId });
            }
         }
      }
      handleCloseAutoCalculate();
   };

   const addMoreAddon = () => {
      if (!quotation.selectedPackage) {
         dialog.alert('Please Select package first!');
         return;
      }
      setNewAddon((prev) => ({ ...prev, openToSelect: true }));
   };

   const onAddAddonClick = () => {
      if (!newAddon.code) {
         return;
      }
      dispatch(addPackageAddon(newAddon.code));
      setNewAddon({ code: null, openToSelect: false });
   };
   const handleSaveQuotationSet = async () => {
      const isConfirm = await dialog.confirm('Are you sure to save this Quotation ?');
      if (!isConfirm) return;
      // todo validate
      // const resValidate = await dispatch(validateBeforeSaveQuotationSet());
      // if (!resValidate) return;

      const onSuccess = (data) => {
         enqueueSnackbar(data.message, {
            variant: 'success',
            autoHideDuration: 2000
         });
         close();
      };
      const onError = (error) => dialog.error(error);
      dispatch(saveQuotationSet({ onSuccess, onError }));
   };

   const getQuantity = (feature) => {
      const quantity = feature.quantity || feature.value;
      if (feature?.feature_detail?.value_type === 'int' && quantity === null) {
         return 'UNLIMITED';
      }
      return quantity ? parseNumber(quantity)?.toLocaleString() : '';
   };

   const handleBlurQty = (addon) => {
      const defaultQty = Number(quotation.defaultTeamRole.minimum);
      let newQty = Number(addon.quantity);
      if (addon.feature_type === 'team-role' && newQty !== defaultQty) {
         if (newQty < defaultQty) newQty = defaultQty;
         else {
            const addStep = Number(quotation.defaultTeamRole.step);
            const modStep = (newQty - defaultQty) % addStep;
            newQty = modStep === 0 ? newQty : newQty - modStep + addStep;
         }
         dispatch(changeAddedAddons(addon.id, 'quantity', newQty));
      }
   };

   // const isSaveAble = () => {
   //    if (!quotation.selectedPackage?.id) return false;
   //    if (quotation.quotationSetGrandTotal < 0) return false;
   //    return true;
   // };
   return (
      <div>
         <Dialog
            classes={{ paper: classes.dialogPaper }}
            open={isOpen}
            // onClose={close}
            aria-labelledby={'quotation-creator'}
            maxWidth="xl"
            fullWidth
         >
            <LoadingBackdrop loading={quotation.isLoading} />
            <DialogTitle id={'quotation-creator'}>
               <b>QUOTATION CREATOR</b>
            </DialogTitle>
            <DialogContent>
               {/* select package */}
               <Box className={classes.packageContainer}>
                  <Grid container spacing={1}>
                     <Grid item xs={12} sm={9}>
                        <Box display="flex" alignItems="center" flexWrap="wrap" style={{ gap: 8 }}>
                           <Box mr={1}>
                              <Typography variant="body1" color="textPrimary">
                                 <b>Package</b>
                              </Typography>
                           </Box>
                           <Box flexGrow={1}>
                              <FormControl
                                 classes={{ root: classes.formControlSelectPackage }}
                                 size="small"
                                 fullWidth
                                 variant="outlined"
                                 disabled={isViewMode}
                              >
                                 <Select
                                    classes={{ disabled: classes.disabledTextField }}
                                    labelId={`select-label-${'createdPackage.id'}`}
                                    value={quotation.selectedPackage?.id ?? ''}
                                    onChange={(e) => dispatch(selectPackage(e.target.value))}
                                    name="package"
                                    // placeholder='Select a package'
                                    displayEmpty
                                 // label="Selected Package"
                                 //   disabled={Boolean(isQuotationSaved || disabled)}
                                 >
                                    <MenuItem value="">
                                       <em>Select a package</em>
                                    </MenuItem>
                                    {quotation.listPackages.map((_package) => {
                                       return _package.isListSubheader ? (
                                          <ListSubheader key={_package.id} className={classes.listSubheader}>
                                             {_package.title}
                                          </ListSubheader>
                                       ) : _package.isListSub ? (
                                          <ListSubheader key={_package.id} className={classes.listSub}>
                                             {_package.title}
                                          </ListSubheader>
                                       ) : (
                                          <MenuItem
                                             key={_package.id}
                                             value={_package.id}
                                             disabled={!_package.can_sale || (previousPackageOrderType === 'free' || previousPackageOrderType === 'normal-free') && (_package.package_order_type === 'staff')}
                                          >
                                             {_package.name} ({_package.package_order_type})
                                          </MenuItem>
                                       );
                                    })}
                                 </Select>
                              </FormControl>
                           </Box>
                           {/* <Box flexGrow={1} /> */}
                           <Box display="flex" alignItems="center" style={{ gap: 8 }}>
                              <Typography variant="body1" color="textPrimary">
                                 <b>SEATS</b>
                              </Typography>
                              <TextField
                                 fullWidth
                                 disabled={isViewMode || quotation.selectedPackage?.package_type === 'individual'}
                                 variant="outlined"
                                 value={quotation.seats || ''}
                                 onChange={(e) => dispatch(onQuotationChangeWithKey('seats', () => e.target.value))}
                                 size="small"
                                 type="number"
                                 InputProps={{
                                    inputProps: { min: quotation.selectedPackage?.minimum_seats || 1 },
                                    classes: { disabled: classes.disabledTextField }
                                 }}
                                 error={!!quotation.error?.seats}
                                 helperText={quotation.error?.seats || null}
                                 FormHelperTextProps={{
                                    classes: { root: classes.helperText }
                                 }}
                              />
                           </Box>
                        </Box>
                        <Box display="flex" alignItems="center" mt={1} flexWrap="wrap">
                           <Typography className={classes.customPackageNameLabel} variant="body1" color="textPrimary">
                              <b>Customize package name</b>
                           </Typography>
                           <TextField
                              disabled={isViewMode}
                              className={classes.customPackageNameTextField}
                              variant="outlined"
                              value={quotation.customizePakageName ?? ''}
                              onChange={(e) =>
                                 dispatch(onQuotationChangeWithKey('customizePakageName', e.target.value))
                              }
                              size="small"
                              InputProps={{
                                 classes: { disabled: classes.disabledTextField }
                              }}
                              error={!!quotation.error?.customizePakageName}
                              helperText={quotation.error?.customizePakageName || null}
                              FormHelperTextProps={{
                                 classes: { root: classes.helperText }
                              }}
                           />
                           {orderType === 'new' &&
                              (quotation?.selectedPackage?.package_order_type === 'normal' ||
                                 quotation?.packageOrderType === 'normal-free') && (
                                 <Box ml={2}>
                                    <FormControlLabel
                                       control={
                                          <Checkbox
                                             checked={quotation.packageOrderType === 'normal-free'}
                                             onChange={(e) =>
                                                dispatch(
                                                   onCheckedPackageNormalFree(
                                                      quotation.selectedPackage?.id,
                                                      e.target.checked
                                                   )
                                                )
                                             }
                                             name="checkedB"
                                             color="primary"
                                          />
                                       }
                                       label="Package normal-free"
                                    />
                                 </Box>
                              )}
                        </Box>
                        <Box p={1} m={0.5} border="1px solid #ddd" borderRadius={8}>
                           <Box p={1}>
                              <Typography variant="body1" color="textPrimary">
                                 Package features
                              </Typography>
                           </Box>
                           <Divider />
                           <Box className={classes.listFeaturePackage}>
                              {(quotation.selectedPackage?.package_feature || []).map((feature) => (
                                 <Box key={feature.feature_code} display="flex" alignItems="center">
                                    <Box mr={1} color="primary.main">
                                       <CheckCircleIcon size={14} />
                                    </Box>
                                    <Typography variant="caption" color="textSecondary" className={classes.featureText}>
                                       {feature.feature_detail?.feature_label || feature.feature_code}
                                    </Typography>
                                    {getQuantity(feature) && (
                                       <Box className={classes.featureQuantityBox}>
                                          <Typography
                                             variant="caption"
                                             color="textSecondary"
                                             className={classes.featureQuantityLabel}
                                          >
                                             Quantity
                                          </Typography>
                                          <Typography variant="caption" className={classes.featureQuantityValue}>
                                             {getQuantity(feature)}
                                          </Typography>
                                       </Box>
                                    )}
                                 </Box>
                              ))}
                           </Box>
                        </Box>
                     </Grid>
                     <Grid item xs={12} sm={3}>
                        <Box display="flex" height="100%" className={classes.packagePriceAreaBox}>
                           <Box className={classes.packagePriceBox}>
                              <Typography variant="body2" color="textSecondary" className={classes.priceBoxLabel}>
                                 <b>Package price per seat</b>
                              </Typography>
                              <Box
                                 display={{ xs: 'block', sm: 'flex' }}
                                 flexGrow={{ xs: 1, sm: 'unset' }}
                                 justifyContent="center"
                                 style={{ textAlign: 'right' }}
                              >
                                 <Typography variant="h5" color="primary" className={classes.packagePriceText}>
                                    {parsePriceForm(quotation.selectedPackage?.price_per_seat) ?? ''}
                                 </Typography>
                              </Box>
                           </Box>
                           <Box className={classes.packagePriceBox}>
                              <Typography variant="body2" color="textSecondary" className={classes.priceBoxLabel}>
                                 <b>Discount</b>
                              </Typography>
                              <Box ml={1} display="flex" alignItems="center" flexGrow={1} flexWrap="wrap">
                                 {quotation.packageDiscount.map(({ disabled, ...discountData }) => (
                                    <MuiChip
                                       key={discountData.id}
                                       disabled={disabled || isViewMode}
                                       size="small"
                                       className={classes.discountChip}
                                       label={
                                          discountData.discount +
                                          (discountData.discount_cal_type === 'percentage' ? '%' : '฿')
                                       }
                                       onDelete={() => dispatch(removePackageDiscount(discountData.id))}
                                       color="primary"
                                       variant="outlined"
                                       onClick={(e) => {
                                          setDiscountForm({
                                             ...discountData,
                                             open: true,
                                             anchorEl: e.currentTarget,
                                             target: 'packageDiscount'
                                          });
                                       }}
                                    />
                                 ))}
                                 {!isViewMode && quotation.packageOrderType !== 'normal-free' && (
                                    <Chip
                                       size="small"
                                       className={classes.discountChip}
                                       label="+Add"
                                       disabled={
                                          quotation.quotationSetNetTotal === 0 || isNaN(quotation.quotationSetNetTotal)
                                       }
                                       color="primary"
                                       variant="outlined"
                                       onClick={(e) => {
                                          setDiscountForm({
                                             ...discountForm,
                                             open: true,
                                             anchorEl: e.currentTarget,
                                             target: 'packageDiscount'
                                          });
                                       }}
                                    />
                                 )}
                              </Box>
                           </Box>
                           <Box className={classes.packagePriceBox}>
                              <Typography variant="body2" color="textSecondary" className={classes.priceBoxLabel}>
                                 <b>Total package price</b>
                              </Typography>
                              <Box
                                 display={{ xs: 'block', sm: 'flex' }}
                                 flexGrow={{ xs: 1, sm: 'unset' }}
                                 justifyContent="center"
                                 style={{ textAlign: 'right' }}
                              >
                                 <Typography variant="h5" color="primary" className={classes.packagePriceText}>
                                    {parsePriceForm(quotation.packageTotalPrice) || ''}
                                 </Typography>
                              </Box>
                           </Box>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
               <Box className={classes.addonsContainer}>
                  <Box p={1}>
                     <Typography variant="body1" color="textPrimary">
                        Addons
                     </Typography>
                  </Box>
                  <Divider />
                  <Box>
                     <Box className={classes.addonsHeadder}>
                        <Box width="40%" textAlign="left" pl={1}>
                           <Typography variant="caption" color="textSecondary">
                              items
                           </Typography>
                        </Box>
                        <Box width="10%" textAlign="center">
                           <Typography variant="caption" color="textSecondary">
                              Price per unit
                           </Typography>
                        </Box>
                        <Box width="10%" textAlign="center">
                           <Typography variant="caption" color="textSecondary">
                              Quantity
                           </Typography>
                        </Box>
                        <Box width="10%" textAlign="center">
                           <Typography variant="caption" color="textSecondary">
                              Unit
                           </Typography>
                        </Box>
                        <Box width="10%" textAlign="center">
                           <Typography variant="caption" color="textSecondary">
                              Use Days
                           </Typography>
                        </Box>
                        <Box width="10%" textAlign="center">
                           <Typography variant="caption" color="textSecondary">
                              Price
                           </Typography>
                        </Box>
                        <Box width="10%" textAlign="center">
                           <Typography variant="caption" color="textSecondary">
                              Actions
                           </Typography>
                        </Box>
                     </Box>
                     {quotation.addedAddons.map((addon, addonIndex) => (
                        <Box key={addon.id} my={0.5} p={1} border="1px solid #eaeef1" borderRadius={8}>
                           <Box display="flex" alignItems="center" className={classes.addonBox}>
                              <Box
                                 width={{ xs: '100vw', md: '40%' }}
                                 px={1}
                                 justifyContent="start"
                                 display="flex"
                                 alignItems="center"
                              >
                                 <Typography variant="body1" color="textPrimary">
                                    {addon.feature_label || addon.code}
                                 </Typography>
                                 {/* <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    className={classes.defaultAddonPrice}
                                 >
                                    <TagIcon size={16} className="priceTag" />{' '}
                                    {parsePriceForm(addon.default_price) + ' ฿'}
                                 </Typography> */}
                              </Box>
                              <Box
                                 width={{ xs: '80px', md: '10%' }}
                                 justifyContent="center"
                                 p={0.5}
                                 display="flex"
                                 alignItems="center"
                              >
                                 <TextField
                                    fullWidth
                                    // disabled
                                    disabled={!!addon.isPackageFeature || isViewMode}
                                    variant="outlined"
                                    value={parseFloat(addon.price_per_unit) ?? ''}
                                    // value={Number(addon.price_per_unit) || ''}
                                    onChange={(e) =>
                                       dispatch(changeAddedAddons(addon.id, 'price_per_unit', e.target.value))
                                    }
                                    size="small"
                                    type="number"
                                    label="price/unit"
                                    InputProps={{
                                       inputProps: { min: 0 },
                                       classes: { disabled: classes.disabledTextField, input: classes.smallTextField }
                                    }}
                                    error={!!quotation.error?.addedAddons?.[addonIndex]?.price_per_unit}
                                    helperText={quotation.error?.addedAddons?.[addonIndex]?.price_per_unit || null}
                                    FormHelperTextProps={{
                                       classes: { root: classes.helperText }
                                    }}
                                 />
                              </Box>
                              <Box
                                 width={{ xs: '100px', md: '10%' }}
                                 justifyContent="center"
                                 p={0.5}
                                 display="flex"
                                 alignItems="center"
                              >
                                 <TextField
                                    fullWidth
                                    disabled={!!addon.isPackageFeature || isViewMode || addon.disabledEditQuantity}
                                    variant="outlined"
                                    value={addon.quantity}
                                    onChange={(e) => dispatch(changeAddedAddons(addon.id, 'quantity', e.target.value))}
                                    onBlur={() => handleBlurQty(addon)}
                                    size="small"
                                    type="number"
                                    label="QTY"
                                    InputProps={{
                                       inputProps: { min: 1 },
                                       classes: { disabled: classes.disabledTextField, input: classes.smallTextField }
                                    }}
                                    error={!!quotation.error?.addedAddons?.[addonIndex]?.quantity}
                                    helperText={quotation.error?.addedAddons?.[addonIndex]?.quantity || null}
                                    FormHelperTextProps={{
                                       classes: { root: classes.helperText }
                                    }}
                                 />
                              </Box>
                              <Box
                                 width={{ xs: 'unset', md: '10%' }}
                                 justifyContent="center"
                                 display="flex"
                                 alignItems="center"
                              >
                                 <Typography variant="body1" color="textPrimary">
                                    {addon.unit ?? ''}
                                 </Typography>
                              </Box>
                              <Box
                                 width={{ xs: 'unset', md: '10%' }}
                                 justifyContent="center"
                                 display={{ xs: addon.feature_type === 'amount' ? 'none' : 'flex', md: 'flex' }}
                                 alignItems="center"
                              >
                                 <Typography variant="body1" color="textPrimary">
                                    {addon.feature_type === 'amount' ? '-' : (addon.days ?? 0) + ' Days'}
                                 </Typography>
                              </Box>
                              <Box
                                 width={{ xs: 'unset', md: '10%' }}
                                 flexGrow={{ xs: 1, md: 'unset' }}
                                 justifyContent={{ xs: 'flex-end', md: 'center' }}
                                 display="flex"
                                 alignItems="center"
                              >
                                 <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    component={Box}
                                    fontWeight={{ xs: 'bold', md: 'unset' }}
                                 >
                                    {parsePriceForm(addon.price) ?? 0}
                                 </Typography>
                              </Box>
                              <Box
                                 width={{ xs: 'unset', md: '10%' }}
                                 justifyContent="center"
                                 display="flex"
                                 alignItems="center"
                              >
                                 <IconButton
                                    className={classes.iconButtonRmAddon}
                                    disabled={isViewMode || !!addon.isPackageFeature}
                                    aria-label="delete"
                                    onClick={() => dispatch(removeAddon(addon.id))}
                                 >
                                    <DeleteIcon fontSize="small" />
                                 </IconButton>
                              </Box>
                           </Box>
                           <Box
                              width="100%"
                              alignItems="center"
                              pl={1}
                              display={addon.isPackageFeature ? 'none' : 'flex'}
                              flexWrap="wrap"
                           >
                              <Typography variant="caption" color="textSecondary">
                                 Discount
                              </Typography>
                              <Box ml={1} display="flex" alignItems="center" flexGrow={1} flexWrap="wrap">
                                 {(addon.addonDiscount || []).map((discountData) => (
                                    <MuiChip
                                       key={discountData.id}
                                       disabled={isViewMode}
                                       size="small"
                                       className={classes.discountChip}
                                       label={
                                          discountData.discount +
                                          (discountData.discount_cal_type === 'percentage' ? '%' : '฿')
                                       }
                                       onDelete={() => dispatch(removeAddonDiscount(discountData.id, addon.id))}
                                       color="primary"
                                       variant="outlined"
                                       onClick={(e) => {
                                          setDiscountForm({
                                             ...discountData,
                                             open: true,
                                             anchorEl: e.currentTarget,
                                             target: 'addonDiscount||' + addon.id
                                          });
                                       }}
                                    />
                                 ))}
                                 {!isViewMode && (
                                    <Chip
                                       size="small"
                                       className={classes.discountChip}
                                       label="+Add"
                                       disabled={
                                          quotation.quotationSetNetTotal === 0 || isNaN(quotation.quotationSetNetTotal)
                                       }
                                       color="primary"
                                       variant="outlined"
                                       onClick={(e) => {
                                          setDiscountForm({
                                             ...discountForm,
                                             open: true,
                                             anchorEl: e.currentTarget,
                                             target: 'addonDiscount||' + addon.id
                                          });
                                       }}
                                    />
                                 )}
                              </Box>
                           </Box>
                        </Box>
                     ))}
                     {!isViewMode && (
                        <Box my={0.5} p={1}>
                           <Button
                              disabled={quotation.packageOrderType === 'normal-free'}
                              variant="text"
                              color="primary"
                              onClick={addMoreAddon}
                           >
                              + add Addons
                           </Button>
                        </Box>
                     )}
                  </Box>
                  <Box display="flex" mt={1}>
                     <Grid container spacing={1}>
                        <Grid item xs={12} md={9} />
                        <Grid item xs={12} md={3}>
                           <Box
                              p={1}
                              width="100%"
                              border="1px solid #ddd"
                              borderRadius={8}
                           // display="flex"
                           // flexDirection="column"
                           // alignItems="center"
                           >
                              <Typography variant="body2" color="textSecondary">
                                 <b>Total Addons price</b>
                              </Typography>
                              <Box display="flex" justifyContent="center">
                                 <Typography variant="h5" color="primary" className={classes.packagePriceText}>
                                    {parsePriceForm(quotation.addonsTotalPrice) ?? 0}
                                 </Typography>
                              </Box>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Box>
               <Box className={classes.conclusionContainer}>
                  <Box>
                     <Grid container alignItems="stretch" spacing={2}>
                        <Grid item xs={12} md={9} className={classes.conclusionLeft}>
                           <Box p={1}>
                              <Typography variant="body1" color="textPrimary">
                                 <b>Note</b>
                              </Typography>
                           </Box>
                           <Divider />
                           <Box flexGrow={1} p={1}>
                              <TextField
                                 disabled={isViewMode}
                                 variant="outlined"
                                 color="primary"
                                 margin="none"
                                 sizes="small"
                                 name="note"
                                 value={quotation.note || ''}
                                 onChange={(e) => dispatch(onQuotationChangeWithKey('note', e.target.value))}
                                 fullWidth
                                 multiline
                                 minRows={5}
                                 InputProps={{
                                    classes: { disabled: classes.disabledTextField }
                                 }}
                              />
                           </Box>
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.conclusionRight}>
                           <Box height="100%" width="100%">
                              <Box p={1}>
                                 <Typography variant="body1" color="textPrimary">
                                    <b>Conclusion</b>
                                 </Typography>
                              </Box>
                              <Divider />

                              <Box my={2} display="flex" alignItems="center" flexWrap="wrap">
                                 <Typography variant="body2" color="textSecondary">
                                    <b>Sub Total</b>
                                 </Typography>
                                 <Box display="flex" justifyContent="center" ml="auto" pr={1}>
                                    <Typography variant="h5" color="primary" className={classes.packagePriceText}>
                                       {parsePriceForm(quotation.quotationSetSubTotal) ?? 0}
                                    </Typography>
                                 </Box>
                              </Box>
                              <Box my={1}>
                                 <Typography variant="body2" color="textSecondary">
                                    <b>Discount</b>
                                 </Typography>
                                 <Box ml={2} display="flex" alignItems="center" flexGrow={1}>
                                    {quotation.quotationSetDiscount.map((discountData) => (
                                       <MuiChip
                                          key={discountData.id || 0}
                                          disabled={
                                             isViewMode ||
                                             discountData.disabled ||
                                             quotation.packageOrderType === 'normal-free'
                                          }
                                          size="small"
                                          className={classes.discountChip}
                                          label={
                                             discountData.discount +
                                             (discountData.discount_cal_type === 'percentage' ? '%' : '฿')
                                          }
                                          onDelete={() => dispatch(removeQuotatinSetDiscount(discountData.id))}
                                          color="primary"
                                          variant="outlined"
                                          onClick={(e) => {
                                             setDiscountForm({
                                                ...discountData,
                                                open: true,
                                                anchorEl: e.currentTarget,
                                                target: 'quotationSetDiscount'
                                             });
                                          }}
                                       />
                                    ))}
                                    {!isViewMode && quotation.packageOrderType !== 'normal-free' && (
                                       <Chip
                                          size="small"
                                          className={classes.discountChip}
                                          label="+Add"
                                          disabled={
                                             quotation.quotationSetNetTotal === 0 ||
                                             isNaN(quotation.quotationSetNetTotal)
                                          }
                                          color="primary"
                                          variant="outlined"
                                          onClick={(e) => {
                                             setDiscountForm({
                                                ...discountForm,
                                                open: true,
                                                anchorEl: e.currentTarget,
                                                target: 'quotationSetDiscount'
                                             });
                                          }}
                                       />
                                    )}
                                 </Box>
                              </Box>
                              {parseNumber(quotation.quotationSetRemain) > 0 && (
                                 <Box my={2} display="flex" alignItems="center" flexWrap="wrap">
                                    <Typography variant="body2" color="textSecondary">
                                       <b>Remain</b>
                                    </Typography>
                                    <Box display="flex" justifyContent="center" ml="auto" pr={1}>
                                       <Typography variant="h5" color="primary" className={classes.packagePriceText}>
                                          -{parsePriceForm(quotation.quotationSetRemain) ?? 0}
                                       </Typography>
                                    </Box>
                                 </Box>
                              )}
                              {parseNumber(quotation.quotationSetUnusePrice) > 0 && (
                                 <Box my={2} display="flex" alignItems="center" flexWrap="wrap">
                                    <Typography variant="body2" color="textSecondary">
                                       <b>Unuse Price</b>
                                    </Typography>
                                    <Box display="flex" justifyContent="center" ml="auto" pr={1}>
                                       <Typography variant="h5" color="primary" className={classes.packagePriceText}>
                                          -{parsePriceForm(quotation.quotationSetUnusePrice) ?? 0}
                                       </Typography>
                                    </Box>
                                 </Box>
                              )}
                              <Box display="flex" justifyContent={'space-between'}>
                                 <Box width={'50%'}>
                                    <Typography variant="body2" color="textSecondary">
                                       <b>Grand Total</b>
                                    </Typography>
                                 </Box>
                                 <Box>
                                    <Chip
                                       size="small"
                                       disabled={
                                          quotation.quotationSetNetTotal === 0 || isNaN(quotation.quotationSetNetTotal)
                                       }
                                       label="Auto Calculate"
                                       color="primary"
                                       onClick={(e) => {
                                          setAutoCalculate({
                                             ...autoCalculate,
                                             open: true,
                                             anchorEl: e.currentTarget,
                                             target: 'quotationSetGrandTotal'
                                          });
                                       }}
                                    />
                                 </Box>
                              </Box>
                              <Box
                                 display="flex"
                                 justifyContent="flex-end"
                                 ml="auto"
                                 pr={1}
                                 border="1px solid #ddd"
                                 borderRadius={8}
                                 marginTop={'10px'}
                              >
                                 <Typography
                                    variant="h5"
                                    color="primary"
                                    className={classes.packagePriceText}
                                    style={{ padding: '10px 5px 10px 10px' }}
                                 >
                                    {parsePriceForm(quotation.quotationSetGrandTotal) ?? 0}
                                 </Typography>
                              </Box>
                              <Box display="flex" justifyContent={'space-between'} marginTop={'10px'}>
                                 <Box width={'50%'}>
                                    <Typography variant="body2" color="textSecondary">
                                       <b>Excluded Vat</b>
                                    </Typography>
                                 </Box>
                                 <Box>
                                    <Chip
                                       size="small"
                                       disabled={
                                          quotation.quotationSetNetTotal === 0 || isNaN(quotation.quotationSetNetTotal)
                                       }
                                       label="Auto Calculate"
                                       color="primary"
                                       onClick={(e) => {
                                          setAutoCalculate({
                                             ...autoCalculate,
                                             open: true,
                                             anchorEl: e.currentTarget,
                                             target: 'quotationSetExcludedVat'
                                          });
                                       }}
                                    />
                                 </Box>
                              </Box>
                              <Box
                                 display="flex"
                                 justifyContent="flex-end"
                                 ml="auto"
                                 pr={1}
                                 border="1px solid #ddd"
                                 borderRadius={8}
                                 marginTop={'10px'}
                              >
                                 <Typography
                                    variant="h5"
                                    color="primary"
                                    className={classes.packagePriceText}
                                    style={{ padding: '10px 5px 10px 10px' }}
                                 >
                                    {parsePriceForm(quotation.quotationSetExcludedVat) ?? 0}
                                 </Typography>
                              </Box>

                              <Box my={2} display="flex" alignItems="center" flexWrap="wrap">
                                 <Typography variant="body2" color="textSecondary">
                                    <b>Vat</b>
                                 </Typography>
                                 <Box display="flex" justifyContent="center" ml="auto" pr={1}>
                                    <Typography variant="h5" color="primary" className={classes.packagePriceText}>
                                       {parsePriceForm(quotation.quotationSetVat) ?? 0}
                                    </Typography>
                                 </Box>
                              </Box>
                              <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                                 <Typography
                                    className={classes.withholdingTaxTLabel}
                                    variant="body2"
                                    color="textSecondary"
                                 >
                                    <b>Withholding tax</b>
                                 </Typography>
                                 <TextField
                                    disabled={isViewMode || type === 'individual'}
                                    className={classes.withholdingTaxTextField}
                                    variant="outlined"
                                    value={quotation.quotationSetWithholdingTax ?? '0'}
                                    onChange={(e) =>
                                       dispatch(onQuotationChangeWithKey('quotationSetWithholdingTax', e.target.value))
                                    }
                                    size="small"
                                    // type="number"
                                    fullWidth
                                    InputProps={{
                                       inputProps: { min: 0 },
                                       classes: { disabled: classes.disabledTextField }
                                    }}
                                 />
                              </Box>
                              <Box mb={2} display="flex" alignItems="center" justifyContent="flex-end">
                                 <Typography variant="caption" style={{ color: 'red' }}>
                                    {'กรณีบุคคลธรรมดา, นิติบุคคล(ราคา < 1,000) ระบุ Witholding tax = 0'}
                                 </Typography>
                              </Box>
                              <Box
                                 mt={2}
                                 p={1}
                                 width="100%"
                                 border="1px solid #ddd"
                                 borderRadius={8}
                              // display="flex"
                              // flexDirection="column"
                              // alignItems="center"
                              >
                                 <Box display="flex" justifyContent={'space-between'}>
                                    <Box width={'50%'}>
                                       <Typography variant="body2" color="textSecondary">
                                          <b>Net price</b>
                                       </Typography>
                                    </Box>
                                    <Box>
                                       <Chip
                                          size="small"
                                          disabled={
                                             quotation.quotationSetNetTotal === 0 ||
                                             isNaN(quotation.quotationSetNetTotal)
                                          }
                                          label="Auto Calculate"
                                          color="primary"
                                          onClick={(e) => {
                                             setAutoCalculate({
                                                ...autoCalculate,
                                                open: true,
                                                anchorEl: e.currentTarget,
                                                target: 'quotationSetDiscount'
                                             });
                                          }}
                                       />
                                    </Box>
                                 </Box>
                                 <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="h5" color="primary" className={classes.packagePriceText}>
                                       {parsePriceForm(quotation.quotationSetNetTotal) ?? 0}
                                    </Typography>
                                 </Box>
                              </Box>
                              <Box display="flex" justifyContent="flex-end" mb={2} pr={1}>
                                 <FormHelperText error={!!quotation?.error?.save}>
                                    {quotation?.error?.save || null}
                                 </FormHelperText>
                              </Box>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Box>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleCloseQuotationCreator} color="primary" variant="outlined">
                  {isViewMode ? 'Close' : 'Cancel'}
               </Button>
               {!isViewMode && (
                  <Button
                     onClick={handleSaveQuotationSet}
                     disabled={!quotation.isCanSave}
                     color="primary"
                     variant="contained"
                  >
                     Save
                  </Button>
               )}
            </DialogActions>
         </Dialog>
         <Popover
            // id={id}
            open={discountForm.open}
            anchorEl={discountForm.anchorEl}
            onClose={handleCloseDiscount}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center'
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center'
            }}
         >
            <Box p={1}>
               <Box display="flex" flexDirection="column">
                  <TextField
                     label="Value"
                     name="discount"
                     value={discountForm.discount}
                     onChange={handleDiscountChange}
                     variant="outlined"
                     size="small"
                     type="number"
                     error={!!discountForm.errorValue}
                     helperText={discountForm.errorValue}
                  />
                  <Box my={1} />
                  <TextField
                     id="standard-select-currency"
                     select
                     size="small"
                     name="discount_cal_type"
                     variant="outlined"
                     label="Discount Type"
                     value={discountForm.discount_cal_type}
                     onChange={handleDiscountChange}
                  >
                     <MenuItem value="percentage">Percentage</MenuItem>
                     <MenuItem value="direct">Direct</MenuItem>
                  </TextField>
               </Box>
               <Box display="flex">
                  <Box flexGrow={1} />
                  <IconButton color="primary" aria-label="save discount" onClick={saveDiscountForm}>
                     <CheckIcon fontSize="small" />
                  </IconButton>
               </Box>
            </Box>
         </Popover>

         <Popover
            // id={id}
            open={autoCalculate.open}
            anchorEl={autoCalculate.anchorEl}
            onClose={handleCloseAutoCalculate}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center'
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center'
            }}
         >
            <Box p={1}>
               <Box display="flex" flexDirection="column">
                  <TextField
                     label="Value"
                     name="price"
                     value={autoCalculate.price}
                     onChange={handlePriceChange}
                     variant="outlined"
                     size="small"
                     type="number"
                     error={!!autoCalculate.errorValue}
                     helperText={autoCalculate.errorValue}
                  />
                  <Box my={1} />
                  <TextField
                     id="standard-select-currency"
                     select
                     size="small"
                     name="price_cal_type"
                     variant="outlined"
                     label="Price Type"
                     value={autoCalculate.price_cal_type}
                     onChange={handlePriceChange}
                  >
                     <MenuItem value="percentage">Percentage</MenuItem>
                     <MenuItem value="direct">Direct</MenuItem>
                  </TextField>
               </Box>
               <Box display="flex">
                  <Box flexGrow={1} />
                  <IconButton color="primary" aria-label="save price" onClick={saveAutoCalculate}>
                     <CheckIcon fontSize="small" />
                  </IconButton>
               </Box>
            </Box>
         </Popover>

         <Dialog open={newAddon.openToSelect} aria-labelledby={'select-addon'} fullWidth maxWidth="sm">
            <DialogTitle id={'select-addon'}>Select Addon To Add</DialogTitle>
            <DialogContent>
               <Box display="flex" alignItems="center">
                  <Typography className={classes.selectAddonLabel} variant="body1" color="textPrimary">
                     Select Addon
                  </Typography>
                  <TextField
                     className={classes.selectAddonTextField}
                     id="standard-select-currency"
                     select
                     size="small"
                     name="type"
                     variant="outlined"
                     SelectProps={{ displayEmpty: true }}
                     value={newAddon.code || ''}
                     onChange={(e) => {
                        if (!e.target.value) {
                           return;
                        }
                        setNewAddon({ ...newAddon, code: e.target.value });
                     }}
                  >
                     <MenuItem value={''}>Select addon</MenuItem>
                     {quotation.listAddons.map((addon) => (
                        <MenuItem
                           key={addon.code}
                           value={addon.code}
                           disabled={
                              quotation.addedAddons.some(
                                 (added) => added.code === addon.code && added.value_type != 'int'
                              ) ||
                              (addon.feature_type === 'team-role' && quotation.selectedPackage.package_type !== 'team')
                           }
                        >
                           {addon.feature_label || addon.code}
                        </MenuItem>
                     ))}
                  </TextField>
               </Box>
            </DialogContent>
            <DialogActions>
               <Button disabled={!newAddon.code} onClick={onAddAddonClick} variant="contained" color="primary">
                  Add
               </Button>
               <Button onClick={() => setNewAddon({ openToSelect: false })} variant="outlined" color="primary">
                  Cancel
               </Button>
            </DialogActions>
         </Dialog>
      </div >
   );
};

export default QuotationCreator;
