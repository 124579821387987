import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { useSnackbar } from 'notistack';
import {
   Box,
   Tabs,
   Tab,
   Button,
   makeStyles,
   alpha,
   Divider,
   Chip,
   Typography,
   Paper,
   FormControlLabel,
   Switch,
   Container
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
   ArrowUpCircle as UpgaredeIcon,
   XCircle as CancelIcon,
   Trash as DeleteIcon,
   UploadCloud as FolderIcon
} from 'react-feather';
import { useDialog } from 'src/hooks/dialog';
import AxiosToken, { axiosToken } from 'src/common/AxiosToken';
import Quotation from './components/Quotation';
import OrderReceipt from './components/OrderReceipt';
import OrderUpload from './components/OrderUpload';
import OrderInvoice from './components/OrderInvoice';
import { useDispatch, useSelector } from 'react-redux';
import { Copy as CopyIcon } from 'react-feather';
import { getOrderById, displayLoading, cleanOrder } from 'src/redux/actions/customerOrderAction';
import { usePermissions } from 'src/hooks/usePermissions';
import NoPermissionView from 'src/views/errors/noPermission/NoPermissionView';
import { useNavigate, useParams } from 'react-router';
import LoadingBackdrop from 'src/components/loading/LoadingBackdrop';
import EditReceipt from './components/EditReceipt';
import Page from 'src/components/Page';
import Header from 'src/components/Header';
import bytesToSize from 'src/common/bytesToSize';
import FeatureLimiTable from './components/FeatureLimitTable';
import EditFeature from './components/EditFeature';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
   },
   customerName: {
      fontSize: 18
   },
   orderHeader: {
      // display: 'flex',
      // alignItems: 'center',
      // [theme.breakpoints.down('sm')]: {
      //    flexDirection: 'column-reverse'
      // }
   },
   alertRoot: {
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
         flexDirection: 'column',
      }
   },
   alertMessage: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
         alignItems: 'center',
         justifyContent: 'center'
      }
   },
   notTab: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
         width: '100%'
      }
   },
   btnCancel: {
      minWidth: 200,
      border: `1px solid ${theme.palette.error.light}`,
      color: theme.palette.error.light,
      backgroundColor: alpha(theme.palette.error.light, 0.08),
      '&:hover': {
         backgroundColor: alpha(theme.palette.error.light, 0.2)
      }
   },
   btnUpgrade: {
      minWidth: 200,
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.light,
      backgroundColor: alpha(theme.palette.primary.light, 0.08),
      '&:hover': {
         backgroundColor: alpha(theme.palette.primary.light, 0.2)
      }
   },
   btnDisabled: {
      border: 'unset'
   },
   btnAction: {
      display: 'flex',
      '& .MuiButton-root': {
         minWidth: 200
      },
      // '& > * + *': {
      //    marginLeft: theme.spacing(1)
      // },
      gap: 8,
      [theme.breakpoints.down(500)]: {
         flexDirection: 'column',
         width: '100%'
      }
   },
   orderTypeAndAction: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
         flexDirection: 'column',
         gap: 8
      }
   },
   btnEditReceipt: {
      display: 'flex',
      minWidth: 200,
      marginRight: '4px',
      [theme.breakpoints.down('sm')]: {
         height: '48px',
      },
      [theme.breakpoints.down('xs')]: {
         width: '100%',
         marginTop: '16px'
      }
   },
   status: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      gap: 4,
      [theme.breakpoints.down('xs')]: {
         alignItems: 'center',
         justifyContent: 'center'
      }
   },
   btnCopyQuotation: {
      // backgroundColor: '#f8f8f8',
      // width: 300,
      paddingLeft: 8,
      paddingRight: 8,
      fontWeight: 600,
      textTransform: 'capitalize'
   },
   footer: {
      [theme.breakpoints.down('xs')]: {
         flexDirection: 'column',
         alignItems: 'center'
      }
   }
}));

const getSeverity = (status) => {
   if (status === 'canceled') {
      return 'error';
   } else if (status === 'verified') {
      return 'success';
   }
   return 'info';
};

const PaymentInfo = ({ data = {} }) => {
   const classes = useStyles();
   const information = data?.info ? JSON.parse(data.info) : {};
   return (
      <Box>
         <Typography color="textPrimary">
            <b>Customer payment info :</b>
         </Typography>
         <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
            <Box style={{ flexGrow: 1 }}>
               <Typography variant="h6" color="textSecondary" className={classes.customerName}>
                  {data.name || ''}
               </Typography>
               {data.branch && (
                  <Typography variant="h6" color="textSecondary">
                     สำนักงาน/สาขา : {data.branch || ''}
                  </Typography>
               )}
               {data.contact_name && (
                  <Typography variant="h6" color="textSecondary">
                     ชื่อผู้ติดต่อ : {data.contact_name || ''}
                  </Typography>
               )}
               <Typography variant="h6" color="textSecondary">
                  อีเมล : {data.email || ''}
               </Typography>
               <Typography variant="h6" color="textSecondary">
                  เบอร์โทร : {data.phone_no || ''}
               </Typography>
               {/* <Box component={Typography} color="textSecondary" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                  {data.name || ''}
               </Box> */}
               {data.tax_id && (
                  <Typography variant="h6" color="textSecondary">
                     เลขประจำตัวผู้เสียภาษี {data.tax_id}
                  </Typography>
               )}
            </Box>
            <Box style={{ flexGrow: 1 }}>
               <Typography variant="h6" color="textSecondary">
                  ที่อยู่ {data.address}
               </Typography>
               <Box display="flex">
                  <Typography variant="h6" color="textSecondary">
                     แขวง/ตำบล : {information?.district?.name_th}
                  </Typography>
                  <Box m={1} />
                  <Typography variant="h6" color="textSecondary">
                     เขต / อำเภอ : {information?.amphure?.name_th}
                  </Typography>
               </Box>
               <Box display="flex">
                  <Typography variant="h6" color="textSecondary">
                     จังหวัด : {information?.province?.name_th}{' '}
                  </Typography>
                  <Box m={1} />
                  <Typography variant="h6" color="textSecondary" pl={2}>
                     รหัสไปรษณีย์ : {data.postal_code}
                  </Typography>
               </Box>
            </Box>
         </Box>
      </Box>
   );
};

function OrderInfo() {
   const dialog = useDialog();
   const classes = useStyles();
   const param = useParams();
   const { enqueueSnackbar } = useSnackbar();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { can, cannot } = usePermissions();
   const customer = useSelector((state) => state.customerOrder.customer);
   const pdfHost = useSelector((state) => state.customerOrder.pdfHost);
   const usageStorage = useSelector((state) => state.customerOrder.usageStorage);
   const order = useSelector((state) => state.customerOrder.order);
   const canCreate = useSelector((state) => state.customerOrder.canCreate);
   const isLoading = useSelector((state) => state.customerOrder.isLoading);
   const isMountedRef = useIsMountedRef();
   const [currentTab, setCurrentTab] = useState('quotation');
   const [isHiddenDetail, setIsHiddenDetail] = useState(false);
   const [isSentReceipt, setIsSentReceipt] = useState(false);
   const [recurring, setRecurring] = useState(false);
   const [featuresCode, setFeaturesCode] = useState([]);
   const [orderFeature, setOrderFeature] = useState([]);
   const [openEditReceipt, setOpenEditReceipt] = useState(false);
   const [dataEditReceipt, setDataEditReceipt] = useState(null);

   const tabs = [
      { value: 'quotation', label: 'Quotation' },
      { value: 'invoice', label: 'Invoice' },
      { value: 'upload', label: 'Upload' },
      { value: 'receipt', label: 'Receipt' }
   ];
   const [permissionTab, setPermissionTab] = useState({
      quotation: 'not',
      invoice: 'not',
      upload: 'not',
      receipt: 'not'
   });

   const handleTabsChange = (event, value) => {
      setCurrentTab(value);
   };
   const handleClickCancelOrder = async () => {
      // check ว่า order นี้ เป็น recurring ล่าสุด
      if (order.id === customer.last_recurring && moment().isBetween(moment(order.package_start), moment(order.package_end))) {
         // แบบมี การsubscribe
         const recurringConfirm = await dialog.confirm('ลูกค้ามีการตั้งค่าการต่ออายุแพ็กเกจอัตโนมัติ(Subscribe) หากทำการยกเลิกคำสั่งซื้อการ Subscribe จะถูกยกเลิก คุณยืนยันที่จะยกเลิกแพ็กเกจหรือไม่?');
         if (!recurringConfirm) return;

         // ยกเลิก subscribe
         const data = {
            user_id: [customer?.id],
            reason: 'payment.cancel-subscribe-reason.staff-cancel-order'
         }
         await axiosToken.post(`order2/unsubscribe`, data);
      } else {
         // แบบไม่มี การsubscribe
         const isConfirm = await dialog.confirm('Are you sure to cancel this order ?');
         if (!isConfirm) return;
      }

      await dispatch(displayLoading(true));
      try {
         const result = await AxiosToken.put(`order2/${order.id}/cancel`);
         console.log('result', result);
         enqueueSnackbar(result.data.message, {
            variant: 'success'
         });
         const shouldLoading = false;
         dispatch(getOrderById(param.orderid, shouldLoading));
      } catch (error) {
         dispatch(displayLoading(false));
         dialog.error(error);
      }
   };
   const handleClickDeleteOrder = async () => {
      const confirm = await dialog.confirm('Are your sure to delete this order?');
      if (confirm) {
         await dispatch(displayLoading(true));
         try {
            const res = await AxiosToken.delete(`order2/${order.id}`);
            enqueueSnackbar(res.data.message, {
               variant: 'success',
               autoHideDuration: 2000
            });
            const shouldLoading = false;
            dispatch(getOrderById(param.orderid, shouldLoading));
         } catch (error) {
            dialog.error(error);
         }
         dispatch(displayLoading(false));
      }
   };
   const handleClickUpgradeOrder = async () => {
      // confirm การอัปเกรด
      if (!!recurring) {
         // แบบมี การsubscribe
         const recurringConfirm = await dialog.confirm('ลูกค้ามีการตั้งค่าการต่ออายุแพ็กเกจอัตโนมัติ(Subscribe) หากทำการอัปเกรดคำสั่งซื้อการ Subscribe จะถูกยกเลิก คุณยืนยันที่จะอัปเกรดหรือไม่?');
         if (!recurringConfirm) return;

         // ยกเลิก subscribe
         const data = {
            user_id: [customer?.id],
            reason: 'payment.cancel-subscribe-reason.staff-upgrade-order'
         }
         await axiosToken.post(`order2/unsubscribe`, data);
      } else {
         // แบบไม่มี การsubscribe
         const isConfirm = await dialog.confirm('Are you sure to upgrade this order ?');
         if (!isConfirm) return;
      }

      try {
         const data = {
            order_type: 'upgrade',
            order_id: order.id
         };
         dispatch(displayLoading(true));
         const res = await axiosToken.post(`customer/${customer?.id}/order2`, data);
         const newOrderid = res.data.new_order_id;
         dispatch(displayLoading(false));
         navigate(`/app/order/${newOrderid}`);
      } catch (error) {
         dispatch(displayLoading(false));
         dialog.error(error);
      }
   };
   const handleCheckHiddenDetail = async (event) => {
      try {
         setIsHiddenDetail(event.target.checked);
         const result = await AxiosToken.put(`order2/${order.id}/hidden-detail/${event.target.checked}`);
         console.log('result', result);
         enqueueSnackbar(result.data.message, {
            variant: 'success'
         });
         const shouldLoading = false;
         dispatch(getOrderById(param.orderid, shouldLoading));
      } catch (error) {
         dispatch(displayLoading(false));
         dialog.error(error);
      }
   };
   const handleCheckSendReceipt = async (event) => {
      try {
         setIsSentReceipt(event.target.checked);
         const result = await AxiosToken.put(`order2/${order.id}/send-receipt/${event.target.checked}`);
         enqueueSnackbar(result.data.message, {
            variant: 'success'
         });
         const shouldLoading = false;
         dispatch(getOrderById(param.orderid, shouldLoading));
      } catch (error) {
         dispatch(displayLoading(false));
         dialog.error(error);
      }
   };

   const handleClickCopyQuotation = async () => {
      const isConfirm = await dialog.confirm('Are you sure to copy quotation as new order');
      if (!isConfirm) return;
      try {
         dispatch(displayLoading(true));
         const urlCopyQuotation = `order2/${order.id}/copy`;
         const result = await axiosToken.post(urlCopyQuotation);
         dispatch(displayLoading(false));
         navigate(`/app/order/${result.data.new_order_id}`);
      } catch (error) {
         dispatch(displayLoading(false));
         dialog.error(error);
      }
   };

   const handleClickEditReceipt = () => {
      setOpenEditReceipt(true);
      setDataEditReceipt(order.payment_info);
   };
   const handleCloseEditReceipt = () => {
      setDataEditReceipt(null);
      setOpenEditReceipt(false);
   };

   const getFeatureCode = useCallback(() => {
      axiosToken
         .get(`/features-code`)
         .then((response) => {
            if (isMountedRef.current) {
               setFeaturesCode(response.data.features_code);
            }
         })
         .catch((error) => {
            console.log('error', error);
         });
   }, [isMountedRef]);

   useEffect(() => {
      getFeatureCode();
   }, [getFeatureCode]);

   const getOrderFeature = useCallback((id) => {
      axiosToken
         .get(`/order2-order-feature/${id}`)
         .then((response) => {
            if (isMountedRef.current) {
               setOrderFeature(response.data.order_feature);
            }
         })
         .catch((error) => {
            console.log('error', error);
         });
   }, [isMountedRef]);

   useEffect(() => {
      getOrderFeature(param.orderid);
   }, [getOrderFeature]);

   const handleLoadOrderFeature = () => {
      getOrderFeature(param.orderid);
   };

   useEffect(() => {
      const cTab = {
         quotation: 'quotation',
         canceled: 'quotation',

         invoice: 'invoice',
         expired: 'invoice',
         credit: 'invoice',

         receive: 'upload',
         verified: 'receipt'
      };
      setCurrentTab(cTab[order.status] || 'receipt');
      setIsHiddenDetail(order?.is_hidden_detail);
      setIsSentReceipt(!!order?.receipt_sent_at);
      // eslint-disable-next-line
   }, [order.status]);

   useEffect(() => {
      setIsHiddenDetail(order?.is_hidden_detail);
      // eslint-disable-next-line
   }, [!!order?.is_hidden_detail]);

   useEffect(() => {
      setIsSentReceipt(!!order?.receipt_sent_at);
      // eslint-disable-next-line
   }, [!!order?.receipt_sent_at]);

   useEffect(() => {
      dispatch(getOrderById(param.orderid));
      getOrderFeature(param.orderid);

      return () => {
         dispatch(cleanOrder());
      };
      // eslint-disable-next-line
   }, [param.orderid]);

   useEffect(() => {
      const hvRecurring = (customer?.order_recurring ?? []).length > 0
      setRecurring(hvRecurring)
   }, [customer])

   if (cannot('VIEW', ['ORDER_QUOTATION', 'ORDER_INVOICE', 'ORDER_RECEIPT'])) {
      return <NoPermissionView />;
   }
   if (!order.id) {
      return <LoadingBackdrop loading={true} />;
   }

   return (
      <Page className={classes.root} title={`${customer?.name || ''} Order`}>
         <Container maxWidth={false}>
            <Header
               beadcrumbs={[
                  { text: 'Dashboard', link: '/app' },
                  { text: 'Customers Order', link: '/app/orders/all-customers' },
                  { text: (customer?.name || '') + ' orders', link: `/app/orders/${customer?.id}` },
                  { text: (customer?.name || '') + ' order' }
               ]}
               title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                     <Box>
                        {customer?.name || ''}
                        <span style={{ fontSize: 11 }}>{' (#ORDER ID : ' + (order?.id || '') + ')'}</span>
                     </Box>
                     <Box
                        px={4}
                        py={0.5}
                        display="flex"
                        borderRadius="8px"
                        bgcolor="secondary.pallid"
                        alignItems="center"
                        justifyContent="space-between"
                     >
                        <Typography variant="caption">ทีมกำลังใช้งาน</Typography>
                        <Box px={2} />
                        <Box display="flex" alignItems="center">
                           <FolderIcon style={{ fontSize: '20px' }} />
                           <Box p={1} />
                           <Typography style={{ fontSize: '24px', fontWeight: 'bold' }}>
                              {bytesToSize(usageStorage)}
                           </Typography>
                        </Box>
                     </Box>
                  </Box>
               }
            />
            <Box>
               <Box p={2} borderRadius={8} bgcolor="#fff" mb={2}>
                  <Box border="1px solid #ddd" p={1} borderRadius={8}>
                     <PaymentInfo data={order.payment_info} />
                     <Box display="flex">
                        <Box flexGrow={1} />
                        <Button
                           className={classes.btnEditReceipt}
                           onClick={handleClickEditReceipt}
                           size="small"
                           variant="outlined"
                           color="primary"
                           disabled={
                              // !(order.status === 'quotation' || order.status === 'invoice') ||
                              cannot('OPERATE', 'ORDER_PAYMENT_INFO')
                           }
                        >
                           EDIT PAYMENT INFO
                        </Button>
                     </Box>
                  </Box>
               </Box>
               <Box p={2} component={Paper} mb={2}>
                  {isLoading && <LoadingBackdrop loading={isLoading} />}
                  <Box className={classes.notTab}>
                     <Box pt={1}>
                        <Alert
                           severity={getSeverity(order.status)}
                           classes={{ root: classes.alertRoot, message: classes.alertMessage }}
                        >
                           <Box className={classes.status}>
                              <Typography variant="body1">Order status : {order.status}</Typography>

                              {order?.receipt && (
                                 <Typography variant="subtitle1">
                                    (Receipt No. : {order?.receipt?.receipt_no})
                                 </Typography>
                              )}
                           </Box>
                           <Box flexGrow={{ xs: 'unset', sm: 1 }} px={{ xs: 1, sm: 0 }} />
                           <Box className={classes.status}>
                              <Typography component="div" variant="subtitle1" color="textSecondary" style={{ fontWeight: 'bold' }}>
                                 Package duration :
                              </Typography>
                              <Typography component="div" variant="subtitle1" color="textSecondary">
                                 {moment(order.package_start).format('DD/MM/YYYY HH:mm') +
                                    (order.package_end === ''
                                       ? ''
                                       : '-' + moment(order.package_end).format('DD/MM/YYYY HH:mm'))}
                              </Typography>
                           </Box>
                        </Alert>
                        <Box my={1} className={classes.orderTypeAndAction}>
                           {order?.code_redemption && (
                              <Box
                                 display="flex"
                                 alignItems="center"
                                 px={1}
                                 py={0.5}
                                 borderRadius="8px"
                                 border="2px dashed lightcoral"
                                 bgcolor="#ff000016"
                              >
                                 {/* <RedeemIcon /> */}
                                 <Typography variant="caption" color="textSecondary">
                                    <b>REDEEM CODE : </b>
                                 </Typography>
                                 <Box p={0.5} />
                                 <Typography>
                                    <b>{order?.code_redemption?.code}</b>
                                 </Typography>
                              </Box>
                           )}
                           <Box flexGrow={1} />
                           <Box
                              display="flex"
                              flexDirection={{ xs: 'column', sm: 'row' }}
                              alignItems="center"
                              justifyContent="center"
                              bgcolor="rgb(244 246 248)"
                              borderRadius={8}
                              p={0.8}
                              mr={{ xs: 0, sm: 2 }}
                              width={{ xs: '100%', md: 'unset' }}
                              style={{ gap: '4px' }}
                           >
                              <Box display="flex" alignItems="center" justifyContent="center" style={{ flexWrap: 'wrap' }}>
                                 <Box mr={0.5}>
                                    <Typography variant="caption" color="textSecondary">
                                       <b>ORDER TYPE :</b>
                                    </Typography>
                                 </Box>
                                 <Chip
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    label={
                                       (order?.order_type || '').toUpperCase() +
                                       (order?.remain_receipt_no ? ' from. ' + order?.remain_receipt_no : '')
                                    }
                                 />
                              </Box>
                              <Box mx={{ xs: 1, sm: 0 }} />
                              {order?.package_order_type && (
                                 <Box display="flex" alignItems="center" justifyContent="center" style={{ flexWrap: 'wrap' }}>
                                    <Box mr={0.5}>
                                       <Typography variant="caption" color="textSecondary">
                                          <b>PACKAGE TYPE :</b>
                                       </Typography>
                                    </Box>
                                    <Chip
                                       size="small"
                                       color="secondary"
                                       variant="outlined"
                                       label={(order?.package_order_type || '').toUpperCase()}
                                    />
                                 </Box>
                              )}
                              <Box mx={{ xs: 1, sm: 0 }} />
                              {order?.quotation?.package_remain_price && (
                                 <Box display="flex" alignItems="center" justifyContent="center" style={{ flexWrap: 'wrap' }}>
                                    <Box mr={0.5}>
                                       <Typography variant="body2">
                                          <b>{'Remain price: '}</b>
                                       </Typography>
                                    </Box>
                                    <Typography variant="body2">
                                       <NumberFormat
                                          value={
                                             order.status === 'quotation'
                                                ? order?.quotation.package_remain_price
                                                : order?.order_package_remain_price
                                          }
                                          displayType={'text'}
                                          decimalScale={2}
                                          thousandSeparator
                                          isNumericString
                                       />
                                    </Typography>
                                 </Box>
                              )}
                           </Box>
                           <Box className={classes.btnAction}>
                              <Button
                                 disabled={!order.can_upgrade || cannot('OPERATE', 'ORDER_QUOTATION')}
                                 // disabled={!order.can_upgrade || permissionTab.quotation !== 'edit'}
                                 size="small"
                                 fullWidth
                                 startIcon={<UpgaredeIcon />}
                                 onClick={handleClickUpgradeOrder}
                                 variant="contained"
                                 classes={{ disabled: classes.btnDisabled }}
                                 className={classes.btnUpgrade}
                              >
                                 Upgrade Package
                              </Button>
                              {order.status?.toLowerCase() !== 'canceled' &&
                                 (order.status === 'quotation' && order.quotation?.package?.length === 0 ? (
                                    <Button
                                       size="small"
                                       fullWidth
                                       onClick={handleClickDeleteOrder}
                                       startIcon={<DeleteIcon />}
                                       variant="contained"
                                       className={classes.btnCancel}
                                       disabled={cannot('OPERATE', 'ORDER_QUOTATION')}
                                    >
                                       DELETE ORDER
                                    </Button>
                                 ) : (
                                    <Button
                                       size="small"
                                       fullWidth
                                       startIcon={<CancelIcon />}
                                       onClick={handleClickCancelOrder}
                                       variant="contained"
                                       className={classes.btnCancel}
                                       disabled={
                                          (order.status === 'quotation' && cannot('OPERATE', 'ORDER_QUOTATION')) ||
                                          (['invoice', 'credit'].includes(order.status) && cannot('OPERATE', 'ORDER_INVOICE')) ||
                                          (order.status === 'receive' && cannot('OPERATE', 'ORDER_RECEIPT')) ||
                                          (order.status === 'verified' && ['free', 'staff'].includes(order.package_order_type) && cannot('OPERATE', 'ORDER_RECEIPT')) ||
                                          (order.status === 'verified' && ['normal', 'private'].includes(order.package_order_type) && cannot('CANCEL_RECEIPT', 'ORDER'))
                                       }
                                    >
                                       Cancel order
                                    </Button>
                                 ))}
                           </Box>
                        </Box>
                     </Box>
                  </Box>
                  <Box className={classes.orderHeader}>
                     <Tabs
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        value={currentTab}
                        variant="fullWidth"
                        centered
                     >
                        {tabs.map((tab) => (
                           <Tab key={tab.value} label={tab.label} value={tab.value} />
                        ))}
                     </Tabs>
                  </Box>
                  <Divider />
                  <Box mt={3}>
                     {currentTab === 'quotation' && (
                        <Quotation
                           order={order}
                           // chosenpackageId={order.chosen_quotation_package_id}
                           quotation={order.quotation}
                           disabled={
                              ['quotation', 'invoice'].every((status) => status !== order.status) ||
                              cannot('OPERATE', 'ORDER_QUOTATION')
                           }
                           pdfHost={pdfHost}
                           isHiddenDetail={isHiddenDetail}
                        />
                     )}
                     {currentTab === 'invoice' && (
                        <OrderInvoice
                           order={order}
                           pdfHost={pdfHost}
                           handleLoadOrderFeature={handleLoadOrderFeature}
                           disabled={cannot('OPERATE', 'ORDER_INVOICE')}
                           isHiddenDetail={isHiddenDetail}
                        />
                     )}
                     {currentTab === 'upload' && (
                        <OrderUpload
                           order={order}
                           disabled={order.status === 'canceled'}
                           handleLoadOrderFeature={handleLoadOrderFeature}
                           canEdit={can(['OPERATE'], 'ORDER_RECEIPT')}
                           canVerify={can(['VERIFY'], 'ORDER_RECEIPT')}
                        />
                     )}
                     {currentTab === 'receipt' && (
                        <OrderReceipt order={order} pdfHost={pdfHost} isHiddenDetail={isHiddenDetail} />
                     )}
                  </Box>
                  {/* <Box textAlign='right' mt={2} mb={-3}> */}
                  <Box display="flex" justifyContent="space-between" mt={2} mb={-1} className={classes.footer}>
                     <Box display="flex" alignItems="center" className={classes.footer}>
                        <Box
                           display="flex"
                           alignItems="center"
                           visibility={
                              (currentTab === 'quotation' && !order?.quotation?.id) ||
                                 (currentTab === 'invoice' && !order?.invoice?.id) ||
                                 currentTab === 'upload' ||
                                 (currentTab === 'receipt' && !order?.receipt?.id)
                                 ? 'hidden'
                                 : 'visible'
                           }
                        >
                           <FormControlLabel
                              disabled={
                                 cannot('OPERATE', 'ORDER_QUOTATION') &&
                                 cannot('OPERATE', 'ORDER_INVOICE') &&
                                 cannot('OPERATE', 'ORDER_RECEIPT')
                              }
                              control={
                                 <Switch
                                    checked={!!isHiddenDetail}
                                    onChange={handleCheckHiddenDetail}
                                    name="checkedB"
                                    color="primary"
                                 />
                              }
                              label="ซ่อนรายละเอียดของเอกสาร"
                           />
                        </Box>
                        <Box
                           display="flex"
                           alignItems="center"
                           visibility={
                              currentTab === 'receipt' &&
                                 order.payment_info?.tax_id &&
                                 order.status === 'verified' &&
                                 ['normal', 'private'].includes(order.package_order_type)
                                 ? 'visible'
                                 : 'hidden'
                           }
                        >
                           <FormControlLabel
                              control={
                                 <Switch
                                    checked={!!isSentReceipt}
                                    onChange={handleCheckSendReceipt}
                                    name="isSentReceipt"
                                    color="primary"
                                 />
                              }
                              label="ทำการส่งใบกำกับภาษีแล้ว"
                           />
                        </Box>
                        <Box
                           visibility={
                              (currentTab === 'quotation' && !order?.quotation?.id) ||
                                 (currentTab === 'invoice' && !order?.invoice?.id) ||
                                 currentTab === 'upload' ||
                                 (currentTab === 'receipt' && !order?.receipt?.id)
                                 ? 'hidden'
                                 : 'visible'
                           }
                        >
                           <Button
                              disabled={!(!!order?.quotation?.set?.length && canCreate)}
                              startIcon={<CopyIcon size={16} />}
                              variant="text"
                              color="primary"
                              size="small"
                              disableElevation
                              className={classes.btnCopyQuotation}
                              onClick={handleClickCopyQuotation}
                           >
                              Copy Quotation As New Order
                           </Button>
                        </Box>
                     </Box>
                     <Box display="flex" justifyContent="center" flexDirection="column">
                        <Typography variant="subtitle1">
                           Created.{' '}
                           {(order?.created_name || '') +
                              (order?.created_at
                                 ? '(' + moment(order?.created_at).format('DD/MM/YYYY HH:mm') + ')'
                                 : '')}
                        </Typography>
                     </Box>
                  </Box>
               </Box>
               {order?.active_package && (
                  <Box p={2} component={Paper}>
                     <Box p={1} >
                        <Typography color="textPrimary">
                           <b>Customer package active </b>
                        </Typography>
                        <Box display="flex" alignItems="center" flexWrap='wrap'>
                           <Typography variant="h6" color="textPrimary">
                              <b>Package</b>
                           </Typography>
                           <Typography style={{ marginLeft: 8 }} variant="h6" color="textSecondary">
                              {order.active_package?.package_name}
                           </Typography>
                        </Box>
                        <Box display="flex" mb={2} flexDirection={{ xs: 'column', sm: 'row' }}>
                           <Box style={{ flexGrow: 1 }}>
                              <Typography variant="h6" color="textSecondary" >
                                 Started at :{moment(order?.active_package?.started_at).format('DD/MM/YYYY HH:mm') || ''}
                              </Typography>
                           </Box>
                           <Box style={{ flexGrow: 1 }}>
                              <Typography variant="h6" color="textSecondary">
                                 Ended at : {moment(order?.active_package?.ended_at).format('DD/MM/YYYY HH:mm') || ''}
                              </Typography>
                           </Box>
                           <Box style={{ flexGrow: 1 }}>
                              <Typography variant="h6" color="textSecondary">
                                 Status : {order?.active_package?.status || ''}
                              </Typography>
                           </Box>
                        </Box>
                        <FeatureLimiTable
                           canEdit={can(['OPERATE'], 'ORDER_FEATURE')}
                           activePackage={order?.active_package}
                           featuresCode={featuresCode}
                           orderFeature={orderFeature}
                           isLoading={isLoading}
                           handleLoadOrderFeature={handleLoadOrderFeature}
                        />
                     </Box>
                  </Box>)}
               <EditReceipt
                  open={openEditReceipt}
                  close={handleCloseEditReceipt}
                  data={dataEditReceipt}
                  orderId={order?.id}
               />


            </Box>
         </Container>
      </Page>
   );
}

export default OrderInfo;
