import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Download as DownloadIcon, PlusSquare as AddIcon } from 'react-feather';

import { onQuotationChangeWithKey, startQuotation } from 'src/redux/actions/quotationAction';
import { usePermissions } from 'src/hooks/usePermissions';
import QuotationCreator from './QuotationCreator';
import QuotationSet from './QuotationSet';
import { successGetOrder } from 'src/redux/actions/customerOrderAction';

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%'
      // backgroundColor: theme.palette.background.paper,
   },
   rootGrid: {
      // padding: 32,
      // borderBottom: "0.5px solid #ddd"
   },
   pdfBtn: {
      backgroundColor: '#f8f8f8',
      width: 200,
      fontWeight: 600
   },
   btnCaculate: {
      margin: '0 8px',
      minWidth: 223
   },
   btnCancel: {
      border: `1px solid ${theme.palette.error.light}`,
      color: theme.palette.error.light,
      backgroundColor: alpha(theme.palette.error.light, 0.08),
      '&:hover': {
         backgroundColor: alpha(theme.palette.error.light, 0.2)
      }
   },
   btnCreateQuotation: {
      width: 'calc( 100% - 40px )',
      margin: '8px 20px',
      border: '1px solid currentColor',
      borderRadius: 4,
      height: 56,
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      '&:hover': {
         backgroundColor: alpha(theme.palette.primary.main, 0.2)
      }
   },
   disabledTextField: {
      color: '#000'
   },
   formControl: {
      '& .MuiSelect-icon.Mui-disabled': {
         color: 'transparent'
      }
   },
   qDiscountInput: {
      '& .MuiOutlinedInput-adornedEnd': {
         paddingRight: 0,
         '& .btn-type-discount': {
            borderRadius: '0 8px 8px 0'
         }
      }
   },
   formControlSubscript: {
      width: 200,
      marginRight: 16
   },
   btnSaveCreatedQuo: {
      width: 150,
      height: 40
   },
   warningStyles: {
      '& .MuiFormLabel-root.Mui-error': {
         color: 'orange !important'
      },
      '& .MuiOutlinedInput-root.Mui-error:after': {
         borderColor: 'orange !important'
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
         borderColor: 'orange !important'
      },
      '& .MuiFormHelperText-root.Mui-error': {
         color: 'orange !important'
      }
   },
   deleteQuoPackage: {
      padding: '4px',
      marginTop: '12px',
      marginLeft: '-8px',
      color: theme.palette.error.light
   }
}));

function Quotation({ order, quotation, disabled, pdfHost }) {
   const classes = useStyles();
   const { can, cannot } = usePermissions();
   const dispatch = useDispatch();
   const chosenQuotationSetId = useSelector((state) => state.customerOrder.order?.chosen_quotation_set_id);
   const orderStatus = useSelector((state) => state.customerOrder.order?.status);
   const [openQuotationCreator, setOpenQuotationCreator] = useState([false, false]);
   const handleClickCreate = () => {
      if (order.payment_info.type === 'individual') {
         dispatch(startQuotation(null, can, 0));
         dispatch(onQuotationChangeWithKey('quotationSetWithholdingTax', 0));
      } else {
         dispatch(startQuotation(null, can));
      }
      setOpenQuotationCreator([true]);
   };
   const handleCloseQuotationCreator = () => {
      setOpenQuotationCreator([false]);
   };

   const handleClickGetPdf = (locale) => {
      window.open(`${pdfHost}/api/${locale}/order/quotation/${quotation.order_id}`, '_blank');
   };

   const canCreateQuotation = !disabled && quotation?.set?.length < 3 && !chosenQuotationSetId;

   if (cannot(['OPERATE', 'VIEW'], 'ORDER_QUOTATION')) {
      return (
         <Box display="flex" alignItems="center" justifyContent="center" height="120px">
            <Typography>You are not permission.</Typography>
         </Box>
      );
   }

   return (
      <div className={classes.root}>
         <Box display="flex" flexDirection="column" minHeight={200}>
            {/* no quotations created */}
            {!canCreateQuotation && !quotation?.set?.length && (
               <Box p={2} display="flex" justifyContent="center" m="auto">
                  <Typography variant="body1" color="textPrimary">
                     <b> Quatation not found</b>
                  </Typography>
               </Box>
            )}

            {/* map crated quotation here */}
            {(quotation?.set || []).map((quotationSet) => (
               <QuotationSet
                  key={quotationSet.id}
                  quotationSet={quotationSet}
                  isSelectAble={['quotation', 'invoice'].includes(orderStatus)}
                  editAble={!chosenQuotationSetId && !disabled}
                  deleteAble={!chosenQuotationSetId && !disabled}
                  copyAble={!!canCreateQuotation}
                  chosenQuotationSetId={chosenQuotationSetId}
                  setOpenQuotationCreator={setOpenQuotationCreator}
               />
            ))}
            {canCreateQuotation && (
               <Button
                  fullWidth
                  startIcon={<AddIcon />}
                  className={classes.btnCreateQuotation}
                  variant="outlined"
                  color="primary"
                  onClick={handleClickCreate}
               >
                  <b>create quotation</b>
               </Button>
            )}
            {!!quotation?.set?.length && (
               <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                     startIcon={<DownloadIcon size={16} />}
                     color="primary"
                     variant="text"
                     size="small"
                     className={classes.pdfBtn}
                     onClick={() => handleClickGetPdf('th')}
                  >
                     pdf th
                  </Button>
                  <Box mx={1} />
                  <Button
                     startIcon={<DownloadIcon size={16} />}
                     variant="text"
                     color="primary"
                     size="small"
                     className={classes.pdfBtn}
                     onClick={() => handleClickGetPdf('en')}
                  >
                     pdf en
                  </Button>
               </Box>
            )}
         </Box>
         <QuotationCreator
            orderType={order.order_type}
            previousPackageOrderType={order?.previous_order?.package_order_type}
            type={order.payment_info.type}
            isOpen={!!openQuotationCreator[0]}
            isViewMode={!!openQuotationCreator[1]}
            close={handleCloseQuotationCreator}
         />
      </div>
   );
}

Quotation.propTypes = {
   disabled: PropTypes.bool
};
Quotation.defaultProps = {
   disabled: false
};

export default Quotation;
