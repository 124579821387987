import React, { useCallback, useEffect, useRef, useState } from 'react';
import CustomersChart from './components/CustomersChart';
import Toolbar from './components/Toolbar';
import Page from 'src/components/Page';
import Header from 'src/components/Header';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useDialog } from 'src/hooks/dialog';
import moment from 'moment';
import axiosToken from 'src/common/AxiosToken';
import { useSearchParams } from 'react-router-dom';
import historyParamsPage from 'src/hooks/storeParams';

const useStyles = makeStyles((theme) => ({
   root: {
      padding: 16
   }
}));

const INITIAL_PARAMS = {
   page_index: 0,
   page_size: 10,
   start_date: moment(new Date()).startOf('month').toISOString(),
   end_date: moment().toISOString(),
   date_type: 'one_month'
};

function checkDurationDate(startDateStr, endDateStr) {
   const startDate = new Date(startDateStr);
   const endDate = new Date(endDateStr);
   const threeMonthsInMillis = 3 * 30 * 24 * 60 * 60 * 1000; // Approx. 3 months in milliseconds
   const threeYearsInMillis = 3 * 365 * 24 * 60 * 60 * 1000; // Approx. 3 years in milliseconds
   const timeDifference = endDate - startDate;

   if (timeDifference >= threeYearsInMillis) {
      return 'in_year';
   } else if (timeDifference >= threeMonthsInMillis) {
      return 'in_month';
   } else if (timeDifference < threeMonthsInMillis) {
      return 'in_day';
   }
}

const CustomersRegistReport = () => {
   const classes = useStyles();
   const isMountedRef = useIsMountedRef();
   const [searchParams, setSearchParams] = useSearchParams();
   const { getParamHistory, storeParamsAsHistory } = historyParamsPage();
   const dialog = useDialog();
   const [isLoading, setIsLoading] = useState(false);
   const [resultdate, setResultDate] = useState({
      start_date: moment(new Date()).startOf('month').toISOString(),
      end_date: null
   });
   const [params, setParams] = useState({
      ...INITIAL_PARAMS,
      ...getParamHistory()
   });
   const isFireOnFirstLoadRef = useRef(true);
   const [responsePackage, setResponsePackage] = useState([]);
   const [responsePackageFree, setResponsePackageFree] = useState([]);
   const [displayPeriod, setDisplayPeriod] = useState('in_day');
   const getChartDateType = {
      all: 'in_year',
      day: 'in_day',
      month: 'in_day',
      year: 'in_month',
      between: '',
      today: 'in_day',
      yesterday: 'in_day',
      this_month: 'in_day',
      last_month: 'in_day',
      this_year: 'in_month',
      last_year: 'in_month',
      one_day: 'in_day',
      three_days: 'in_day',
      one_week: 'in_day',
      three_weeks: 'in_day',
      one_month: 'in_day',
      three_months: 'in_day',
      six_months: 'in_month',
      one_year: 'in_month'
   };

   const getChartData = useCallback(
      async (queryParams) => {
         setIsLoading(true);
         try {
            const res = await axiosToken.get('survey-response-summary-report', { params: queryParams });
            if (isMountedRef.current) {
               setResponsePackage(res.data.survey_response);
               setResponsePackageFree(res.data.survey_response_free);
               setResultDate({ start_date: res.data.start_date, end_date: res.data.end_date });
               if (res.data.date_type === 'between') {
                  setDisplayPeriod(checkDurationDate(res.data.start_date, res.data.end_date));
               } else {
                  setDisplayPeriod(getChartDateType[res.data.date_type]);
               }
               setIsLoading(false);
            }
         } catch (error) {
            setIsLoading(false);
            console.log('error', error);
            dialog.error(error);
         }
         // eslint-disable-next-line
      },
      [isMountedRef]
   );

   const startSearching = (value = {}) => {
      const newParams = { ...params, ...value, page_index: 0 };
      getChartData(newParams);
      setParams(newParams);
      return newParams;
   };

   const startSearchingAndStoreParams = (value) => {
      const newParams = startSearching(value);
      storeParamsAsHistory(newParams);
   };

   const onResetClick = () => {
      getChartData(INITIAL_PARAMS);
      setParams(INITIAL_PARAMS);
      setSearchParams({});
   };

   useEffect(() => {
      if (isFireOnFirstLoadRef.current) {
         getChartData(params);
      }
      isFireOnFirstLoadRef.current = false;
   }, [getChartData]);

   return (
      <Page className={classes.root} title="Survey response summary report">
         <Container maxWidth={false}>
            <Header
               beadcrumbs={[{ text: 'Dashboard', link: '/app' }, { text: 'Survey response summary report' }]}
               title="Survey response summary report"
            />
            <Toolbar
               params={params}
               setParams={setParams}
               startSearching={startSearching}
               startSearchingAndStoreParams={startSearchingAndStoreParams}
               onResetClick={onResetClick}
               isLoading={isLoading}
            />
            <CustomersChart
               params={params}
               responsePackage={responsePackage}
               responsePackageFree={responsePackageFree}
               resultdate={resultdate}
               displayPeriod={displayPeriod}
               setDisplayPeriod={setDisplayPeriod}
               isLoading={isLoading}
            />
         </Container>
      </Page>
   );
};

export default CustomersRegistReport;
